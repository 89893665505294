import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../../shared/services/user.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BaseService } from '../../shared/services/base.service';
import { PhoneMask } from '../../shared/masks/phone.mask';
import { api } from '../../../environments/api';
import { Subscription } from 'rxjs/Subscription';
import { DatePipe } from '@angular/common';
import { CustomValidators } from '../../shared/validators/custom.validators';
import { UserInterface } from '../../shared/interfaces/user/user.interface';
import { EditUserResponseInterface } from '../../shared/interfaces/user/edit-user-response.interface';
import { CurrentUserResponseInterface } from '../../shared/interfaces/user/current-user-response.interface';
import { gender } from '../../shared/catalogs/main.catalog';
import { TextMaskConfig } from 'angular2-text-mask';
import { MatDialog } from '@angular/material';
import { DialogInterface } from '../../shared/interfaces/dialog.interface';
import { AccountDialog } from '../account.component';
import { formatPhone } from '../../shared/functions/functions';

@Component({
  selector: 'app-account-edit',
  templateUrl: './account-edit.component.html',
  styleUrls: ['./account-edit.component.scss']
})
export class AccountEditComponent implements OnInit {

  userCurrentData: UserInterface;
  form: FormGroup;
  cityFias: string;
  errorTitle: string = api.mainErrorTitle;
  errorMessage: string = api.mainErrorMessage;
  preloaderVisibility: boolean = false;
  timeout: boolean = true;
  phone: string;
  genderSelect = gender;
  birthday: Date;
  maxDate: Date = new Date();

  phoneMask: TextMaskConfig = {
    mask: PhoneMask
  };

  editUserSubscribe: Subscription;
  getCurrentUserDataSubscribe: Subscription;

  constructor(
    public userService: UserService,
    public baseService: BaseService,
    public router: Router,
    private dialog: MatDialog,
    private datePipe: DatePipe,
    private customValidators: CustomValidators
  ) {}

  ngOnInit() {
    this.userCurrentData = this.userService.getUserData().UserData;

    let phone = this.userCurrentData.Phone;

    phone
      ? this.phone = phone.slice(1)
      : this.phone = null;

    this.checkBirthday();

    this.baseService.postSuggestion(this.userCurrentData.City)
      .subscribe((response: any)=>{
      response.suggestions.length
        ? this.cityFias = response.suggestions[0].data.city_fias_id
        : null;
    });

    this.form = new FormGroup({
      'email': new FormControl(this.userCurrentData.Email, [Validators.required, this.customValidators.email]),
      'phone': new FormControl(this.phone),
      'firstName': new FormControl(this.userCurrentData.Firstname, [Validators.required]),
      'surName': new FormControl(this.userCurrentData.Surname, [Validators.required]),
      'gender': new FormControl(this.userCurrentData.Gender, [Validators.required]),
      'birthday': new FormControl(this.birthday),
      'city': new FormControl(this.userCurrentData.City, [Validators.required]),
      'address': new FormControl(this.userCurrentData.Address),
      'aboutUser': new FormControl(this.userCurrentData.AboutSpecialist),
    });

    console.log(this.form);
  }

  checkBirthday() {
    if(this.userCurrentData.BirthDate != '0001-01-01') {
      this.birthday = new Date(this.userCurrentData.BirthDate);
    }
  }

  setCityFias(response) {
    this.cityFias = response;
    this.form.get('address').setValue('');
  }

  onSubmit() {
    this.preloaderVisibility = true;

    const value = this.form.value;

    if(value.birthday != '01.01.0001' && value.birthday != this.userCurrentData.BirthDate) {
      value.birthday = this.datePipe.transform(value.birthday, 'MM.dd.yyyy');
    }

    let phone = null;

    if(value.phone) {
      phone = formatPhone(value.phone);
    }

    const userCurrentEditData: UserInterface = {
      Firstname: value.firstName,
      Surname: value.surName,
      Gender: value.gender,
      City: value.city,
      Address: value.address,
      BirthDate: value.birthday,
      AboutSpecialist: value.aboutUser,
      Email: value.email,
      Phone: phone,
    };

    console.log(this.form);

    this.editUserSubscribe = this.userService.editCurrentUserData(userCurrentEditData)
      .subscribe((response: EditUserResponseInterface) => {
      console.log('EditUserResult', response);

      if(response.EditUserResult.Success) {
        this.getCurrentUserDataSubscribe = this.userService.getCurrentUserData()
          .subscribe((response: CurrentUserResponseInterface) => {

          this.timeout = false;

          if(response.CurrentUserResult.Success) {
            this.userService.setUserData(response.CurrentUserResult);
            console.log('CurrentUserResult:', response.CurrentUserResult.UserData);
            this.userCurrentData = response.CurrentUserResult.UserData as UserInterface;
            this.checkBirthday();
            this.openDialog(
              'Информация успешно изменена',
              'Вы успешно внесли измения в информацию о себе!',
              false,
              'back-profile'
            );
          } else {
            this.openDialog(
              this.errorTitle,
              this.errorMessage,
              true
            );
          }
        });
      } else {

        this.timeout = false;

        const warnings = response.EditUserResult.Warnings;
        let errors: string[] = [];
        let errorMessage = this.errorMessage;
        let errorTitle = this.errorTitle;

        for(let key in warnings) {
          if(warnings[key].FieldName == 'Request.UserData.Phone') {
            errors.push('Пользователь с таким телефоном уже существует. Пожалуйста укажите другой телефон!');
          }
          if(warnings[key].FieldName == 'Request.UserData.Email') {
            errors.push('Пользователь с таким E-mail уже существует. Пожалуйста укажите другой E-mail!');
          }
        }

        if(errors.length) {
          errorMessage = '';
          errorTitle = 'Ошибка!';

          for(let key in errors) {
            errorMessage += errors[key];
            if(parseInt(key) != errors.length - 1) {
              errorMessage += '<br><br>';
            }
          }
        }

        this.openDialog(
          errorTitle,
          errorMessage,
          true
        );

      }
    });

    setTimeout(() => {
      if (this.timeout) {
        this.openDialog(
          this.errorTitle,
          this.errorMessage,
          true
        );
        this.editUserSubscribe.unsubscribe();
        this.getCurrentUserDataSubscribe ? this.getCurrentUserDataSubscribe.unsubscribe() : null;
      }
    }, 30000);
  }

  showForm() {
    console.log(this.form);
    console.log(this.datePipe.transform(this.form.value.birthday, 'MM.dd.yyyy'));
  }

  openDialog(
    title: string,
    message: string,
    error: boolean = false,
    back: string = null
  ) {

    const data: DialogInterface = {
      title: title,
      message: message,
      error: error,
      back: back
    };

    const dialogRef = this.dialog.open(AccountDialog, {
      data: data
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.preloaderVisibility = false;

      result == 'back-profile'
        ? this.router.navigate(['/account'])
        : null;
    });
  }
}

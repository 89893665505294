import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ageName'
})
export class AgeNamePipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if(value == '0001-01-01') {
      return 'Не указано'
    }

    const birthday = new Date(value);
    const ageDifMs = Date.now() - birthday.getTime();
    const ageDate = new Date(ageDifMs);
    const age = Math.abs(ageDate.getFullYear() - 1970);

    let text,
        count;

    count = age % 100;
    if (count >= 5 && count <= 20) {
      text = 'лет';
    } else {
      count = count % 10;
      if (count == 1) {
        text = 'год';
      } else if (count >= 2 && count <= 4) {
        text = 'года';
      } else {
        text = 'лет';
      }
    }
    return age+" "+text;
  }

}

import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { UserService } from '../../services/user.service';
import { api } from '../../../../environments/api';
import { Subscription } from 'rxjs/Subscription';
import { EditUserResponseInterface } from '../../interfaces/user/edit-user-response.interface';
import { CropperSettings, ImageCropperComponent } from '../../plugins/cropper';
import { downscaleImage } from '../../functions/functions';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { UserInterface } from '../../interfaces/user/user.interface';

@Component({
  selector: 'app-account-change-photo',
  templateUrl: './account-change-photo.component.html',
  styleUrls: ['./account-change-photo.component.scss']
})
export class AccountChangePhotoComponent implements OnInit {
  files: File[] = [];
  showPreview: boolean = false;
  isFile: boolean = false;
  preloaderVisibility: boolean = false;
  timeout: boolean = true;
  changePhotoSubscribe: Subscription;
  lastInvalidFiles: File[];
  showError: boolean = false;
  errorText: string;

  data: any;
  cropperSettings: CropperSettings;
  cropperSettings2: CropperSettings;
  currentAvatar = this.dialogData.avatar;
  avatarId = this.dialogData.avatarId;
  userData: UserInterface;

  @ViewChild('cropper', undefined) cropper: ImageCropperComponent;
  @ViewChild('cropperWrapper') cropperWrapper: ElementRef;

  constructor(
    private userService: UserService,
    @Inject(MAT_DIALOG_DATA) public dialogData,
    public dialogRef: MatDialogRef<AccountChangePhotoComponent>,
  ) {
    this.cropperSettings = new CropperSettings();
    this.cropperSettings.width = 200;
    this.cropperSettings.height = 200;
    this.cropperSettings.keepAspect = false;

    this.cropperSettings.croppedWidth = 200;
    this.cropperSettings.croppedHeight = 200;

    this.cropperSettings.canvasWidth = 460;

    this.cropperSettings.minWidth = 60;
    this.cropperSettings.minHeight = 60;

    this.cropperSettings.rounded = true;
    this.cropperSettings.minWithRelativeToResolution = false;

    this.cropperSettings.cropperClass = 'cropper-none';
    this.cropperSettings.croppingClass = 'cropper-cropping';
    this.cropperSettings.noFileInput = true;
    this.cropperSettings.dynamicSizing = true;
    this.cropperSettings.cropperDrawSettings.strokeColor = 'rgba(255,255,255,1)';
    this.cropperSettings.cropperDrawSettings.strokeWidth = 2;
    this.cropperSettings.fileType = 'image/jpeg';

    this.cropperSettings2 = new CropperSettings();
    this.cropperSettings2.width = 200;
    this.cropperSettings2.height = 200;
    this.cropperSettings2.keepAspect = false;

    this.cropperSettings2.croppedWidth = 200;
    this.cropperSettings2.croppedHeight = 200;

    this.cropperSettings2.canvasWidth = 500;
    this.cropperSettings2.canvasHeight = 300;

    this.cropperSettings2.minWidth = 100;
    this.cropperSettings2.minHeight = 100;

    this.cropperSettings2.rounded = true;
    this.cropperSettings2.minWithRelativeToResolution = false;

    this.cropperSettings2.cropperDrawSettings.strokeColor = 'rgba(255,255,255,1)';
    this.cropperSettings2.cropperDrawSettings.strokeWidth = 2;
    this.cropperSettings2.noFileInput = true;
    this.cropperSettings2.fileType = 'image/jpeg';

    this.data = {};
  }

  ngOnInit() {
    this.clearFile();
    this.userData = this.userService.getUserData().UserData;
  }

  changeFiles() {
    if(this.files.length) {
      this.showError = false;
      this.showPreview = true;

      const file = this.files[0];

      let reader = new FileReader();

      let image: any = new Image();
      let t = this;

      reader.readAsDataURL(file);
      reader.onload = () => {
        image.src = reader.result;
        t.cropper.setImage(image);
        this.isFile = true;
      }

    } else {
      this.showPreview = false;
      this.isFile = false;
    }
  }

  lastInvalidsChange() {
    if(this.lastInvalidFiles) {
      this.showError = true;
      this.errorText = this.lastInvalidFiles[0].type == 'fileSize' ? 'Размер файла слишком большой': 'Неизвестаня ошибка';
    } else {
      this.showError = false;
    }

  }

  clearFile() {
    this.showPreview = false;
    this.isFile = false;
    this.showError = false;
    this.files = [];
  }

  changePhoto() {

    const name = this.files[0].name;

    const content = downscaleImage(this.data.image,400).split(',')[1];

    let request;

    if(this.avatarId) {
      request = {
        Id: this.avatarId,
        Name: name,
        Content: content
      };
    } else  {
      request = {
        Name: name,
        Content: content
      };
    }

    this.preloaderVisibility = true;

    this.changePhotoSubscribe = this.userService.changePhoto(request)
      .subscribe((response: EditUserResponseInterface) => {
        console.log(response);

        if(response.EditUserResult.Success) {
          this.userService.userData.Files = response.EditUserResult.Files;
          this.timeout = false;
          this.clearFile();
          this.dialogRef.close({
            success: true
          });
        } else {
          this.preloaderVisibility = false;
          this.timeout = false;
          this.clearFile();
          this.showError = true;
          this.errorText = api.mainErrorMessage;
        }
      });

    setTimeout(() => {
      if (this.timeout) {
        this.preloaderVisibility = false;
        this.clearFile();
        this.showError = true;
        this.errorText = api.mainErrorMessage;
        this.changePhotoSubscribe.unsubscribe();
      }
    }, 30000);
  }
}

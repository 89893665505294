import { Pipe, PipeTransform } from '@angular/core';
import { SelectInterface } from '../interfaces/select.interface';

@Pipe({
  name: 'catalogName'
})
export class CatalogNamePipe implements PipeTransform {

  transform(value: any, catalog: SelectInterface[]): any {

    if(value && catalog) {
      let name;

       catalog.forEach( (el)=> {
        if(el.value === value) {
          name = el.name;
        }
      });

      return name;

    } else {
      return null;
    }
  }

}

import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '../../shared/services/user.service';
import { AuthService } from '../../shared/services/auth.service';
import { api } from '../../../environments/api';
import { Subscription } from 'rxjs';
import { ConfirmResponseInterface } from '../../shared/interfaces/auth/confirm-response.interface';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material';

@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss']
})
export class ConfirmComponent implements OnInit {

  errorMessage: string = api.mainErrorMessage;
  errorTitle: string = api.mainErrorTitle;
  timeout: boolean = true;
  confirmSubscribe: Subscription;
  hash: string;

  constructor(
    private router: Router,
    private userService: UserService,
    private authService: AuthService,
    private dialog: MatDialog,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.hash = this.route.snapshot.queryParamMap.get('hash');

    this.hash
      ? this.hash = this.hash.replace(/ /g, '+')
      : null;

    console.log('Hash: ' + this.hash);

    this.confirmSubscribe = this.authService.confirmRegistration(this.hash)
      .subscribe((response: ConfirmResponseInterface) => {

      !api.production ? console.log('ConfirmCommunicationByCodeResult:', response) : null;

      this.timeout = false;

      if(response.ConfirmCommunicationByCodeResult.Success) {
        this.openDialog(
          'Вы подтвердили почту!',
          'Ваша регистрация успешно подтверждена. <br> Теперь вы можете пользоваться всеми возможностями нашего сервиса'
        );
      } else {

        const warnings = response.ConfirmCommunicationByCodeResult.Warnings;

        let errorMessage = this.errorMessage;
        let errorTitle = this.errorTitle;

        for(let key in warnings) {
          if(warnings[key].Code == 507) {
            errorTitle = 'Вы уже подтвердили свою почту!';
            errorMessage = 'Ваша почта уже подтверждена, повторное подтверждение не требуется.';
          }
        }

        this.openDialog(
          errorTitle,
          errorMessage,
          true
        );
      }
    });

    setTimeout(()=> {
      if(this.timeout) {
        this.openDialog(
          this.errorTitle,
          this.errorMessage,
          true
        );
        this.confirmSubscribe.unsubscribe();
      }
    }, 30000);
  }

  openDialog(
    title: string,
    message: string,
    error: boolean = false,
  ) {
    const dialogRef = this.dialog.open(ConfirmDialog, {
      data: {
        title: title,
        message: message,
        error: error
      }
    });

    if(error) {
      dialogRef.afterClosed().subscribe(() => {
        this.router.navigate(['/auth/sign-in']);
      });
    } else {
      dialogRef.afterClosed().subscribe(() => {
        this.router.navigate(['/account']);
      });
    }
  }

}

@Component({
  selector: 'confirm-dialog',
  templateUrl: 'confirm-dialog.html',
})
export class ConfirmDialog {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
  ) {}
}

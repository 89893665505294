import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from '../shared/services/auth.guard';
import { AccountComponent } from './account.component';
import { AccountEditComponent } from './account-edit/account-edit.component';
import { AccountInfoComponent } from './account-info/account-info.component';
import { AccountChangePasswordComponent } from './account-change-password/account-change-password.component';
import { AccountPetsComponent } from './account-pets/account-pets.component';
import { AccountAddPetComponent } from './account-pets/account-add-pet/account-add-pet.component';
import { AccountEditPetComponent } from './account-pets/account-edit-pet/account-edit-pet.component';
import { AccountInfoPetsComponent } from './account-pets/account-info-pets/account-info-pets.component';
import { AccountListPetsComponent } from './account-pets/account-list-pets/account-list-pets.component';

const routes: Routes = [
  {
    path: 'account', component: AccountComponent, canActivate: [AuthGuard], children: [
      { path: '', redirectTo: 'info', pathMatch: 'full' },
      { path: 'info', component: AccountInfoComponent },
      { path: 'edit', component: AccountEditComponent },
      { path: 'change-password', component: AccountChangePasswordComponent },
      {
        path: 'pets', component: AccountPetsComponent, children: [
          { path: '', redirectTo: 'list', pathMatch: 'full' },
          {path: 'list', component: AccountListPetsComponent},
          {path: 'add', component: AccountAddPetComponent},
          {path: 'edit/:id', component: AccountEditPetComponent},
          {path: 'info/:id', component: AccountInfoPetsComponent}
        ]
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AccountRoutingModule {
}

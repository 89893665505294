import { Component, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { PetsService } from '../../../shared/services/pets.service';
import {
  petBreedCat,
  petBreedDog,
  petConditionKeep, petGender, petIsNeutered,
  petLengthCoat,
  petPlaceKeep, petProvenance, petSizes,
  petTypes, petVaccinationType
} from '../../../shared/catalogs/pets.catalog';
import { DatePipe } from '@angular/common';
import { api } from '../../../../environments/api';
import { Subscription } from 'rxjs';
import { RegistrationPetResponseInterface } from '../../../shared/interfaces/pets/registration-pet-response.interface';
import { PetAdditionalsInterface } from '../../../shared/interfaces/pets/pet-additionals.interface';
import { Router } from '@angular/router';
import { FileInterface } from '../../../shared/interfaces/file.interface';
import { SelectInterface } from '../../../shared/interfaces/select.interface';
import { PetInterface } from '../../../shared/interfaces/pets/pet.interface';
import { dateCompilation, downscaleImage } from '../../../shared/functions/functions';
import { DialogInterface } from '../../../shared/interfaces/dialog.interface';
import { AccountDialog } from '../../account.component';
import { MatDialog } from '@angular/material';
import { PetVaccinationInterface } from '../../../shared/interfaces/pets/pet.vaccination.interface';
import { CustomValidators } from '../../../shared/validators/custom.validators';

@Component({
  selector: 'app-account-add-pet',
  templateUrl: './account-add-pet.component.html',
  styleUrls: ['./account-add-pet.component.scss']
})
export class AccountAddPetComponent implements OnInit {

  errorTitle: string = api.mainErrorTitle;
  errorMessage: string = api.mainErrorMessage;
  preloaderVisibility: boolean = false;
  timeout: boolean = true;

  form: FormGroup;
  previousType: string = petTypes[0].value as string;
  showDogSize: boolean = false;
  registrationPetSubscribe: Subscription;
  vaccinations: FormArray;

  files: File[] = [];
  isFile: boolean = false;
  lastInvalidFiles: File[];
  showFileError: boolean = false;
  currentAvatar: string;
  filesFull: boolean = false;
  newFiles: FileInterface[];
  hideBreed = false;

  maxDate: Date = new Date();

  selectType: SelectInterface[] = petTypes;
  selectBreed: SelectInterface[] = petBreedCat;
  selectLenthCoat: SelectInterface[] = petLengthCoat;
  selectPlaceKeep: SelectInterface[] = petPlaceKeep;
  selectConditionKeep: SelectInterface[] = petConditionKeep;
  selectProvenance: SelectInterface[] = petProvenance;
  selectGender: SelectInterface[] = petGender;
  selectIsNeutered: SelectInterface[] = petIsNeutered;
  selectSize: SelectInterface[] = petSizes;
  selectVaccination: SelectInterface[] = petVaccinationType;

  constructor(
    private petService: PetsService,
    private datePipe: DatePipe,
    private dialog: MatDialog,
    private router: Router,
    private customValidators: CustomValidators
  ) {}

  ngOnInit() {
    this.formInit();
    this.currentAvatar = '/assets/images/pet-placeholder.jpg';
  }

  formInit() {
    this.form = new FormGroup({
      'name': new FormControl(null, Validators.required),
      'type': new FormControl(petTypes[0], [Validators.required, this.customValidators.autofillForceRequire]),
      'breed': new FormControl(null, [this.customValidators.autofillForceRequire]),
      'lengthCoat': new FormControl(null),
      'birthday': new FormControl(null),
      'gender': new FormControl(null),
      'growth': new FormControl(null),
      'weight': new FormControl(null),
      'placeKeep': new FormControl(null),
      'conditionKeep': new FormControl(null),
      'chipNumber': new FormControl(null),
      'provenance': new FormControl(null),
      'lastDeworming': new FormControl(null),
      'lastTreatment': new FormControl(null),
      'lastRabies': new FormControl(null),
      'lastComprehensive': new FormControl(null),
      'isNeutered': new FormControl(false),
      'dermatosisHairLoss': new FormControl(false),
      'heartFailure': new FormControl(false),
      'kidneyDisease': new FormControl(false),
      'sensitiveDigestion': new FormControl(false),
      'diseasesJoint': new FormControl(false),
      'allergy': new FormControl(false),
      'obesity': new FormControl(false),
      'liverDisease': new FormControl(false),
      'urolithiasisDisease': new FormControl(false),
      'recovery': new FormControl(false),
      'diabetes': new FormControl(false),
      'size': new FormControl(null),
      'mark': new FormControl(null),
      'petUpkeepInformation': new FormControl(null),
      // 'vaccination': new FormArray([])
    });

    // this.vaccinations = this.form.get('vaccination') as FormArray;
  }

  addVaccination(): void {
    this.vaccinations.push(
      new FormGroup({
        'id': new FormControl(),
        'title': new FormControl(null, [Validators.required]),
        'date': new FormControl(null, [Validators.required]),
        'dateNext': new FormControl(null, [Validators.required]),
        'type': new FormControl(this.selectVaccination[0].value, [Validators.required])
      })
    );
  }

  removeVaccination(i): void {
    this.vaccinations.removeAt(i);
  }

  changeType() {
    !api.production ? console.log('change-type', this.form.value.type.value) : null;

    if (this.form.value.type.value != this.previousType) {

      this.previousType = this.form.value.type.value;

      if (this.form.value.type.value == petTypes[0].value) {
        this.selectBreed = petBreedCat;
        this.showDogSize = false;
        this.hideBreed = false;
        this.form.controls['size'].setValue(null);
      } else if (this.form.value.type.value == petTypes[1].value) {
        this.selectBreed = petBreedDog;
        this.showDogSize = true;
        this.hideBreed = false;
      } else {
        this.hideBreed = true;
      }

      this.form.value.breed = null;
    }
  }

  preSubmit() {
    if (this.isFile) {

      let convertFiles: FileInterface[] = [];

      for (let i = 0; i < this.files.length; i++) {
        let reader = new FileReader();

        const name = this.files[i].name;
        const file = this.files[i];

        reader.readAsDataURL(file);

        reader.onload = () => {
          const content = downscaleImage(reader.result, 600).split(',')[1];

          console.log('test', reader.result);

          convertFiles.push({
            Name: name,
            Content: content
          });
        };
      }

      const interval = setInterval(() => {
        if (convertFiles.length == this.files.length) {
          clearInterval(interval);

          this.newFiles = convertFiles;
          this.onSubmit();
        }
      }, 100);
    } else {
      this.onSubmit();
    }
  }

  onSubmit() {
    const value = this.form.value;

    this.preloaderVisibility = true;

    console.log(value);

    let files = [];

    if (this.isFile) {
      files = this.newFiles;
    }

    value.birthday ? value.birthday = this.datePipe
      .transform(value.birthday, 'MM.dd.yyyy') : null;

    value.lastDeworming
      ? value.lastDeworming = dateCompilation(value.lastDeworming)
      : null;

    value.lastTreatment
      ? value.lastTreatment = dateCompilation(value.lastTreatment)
      : null;

    value.lastRabies
      ? value.lastRabies = dateCompilation(value.lastRabies)
      : null;

    value.lastComprehensive
      ? value.lastComprehensive = dateCompilation(value.lastComprehensive)
      : null;

    const growth: PetAdditionalsInterface = value.growth
      ? {
        Date: this.datePipe.transform(new Date(), 'MM.dd.yyyy'),
        Value: value.growth
      }
      : null;

    const weight: PetAdditionalsInterface = value.weight
      ? {
        Date: this.datePipe.transform(new Date(), 'MM.dd.yyyy'),
        Value: value.weight
      }
      : null;

    /*let vaccinations: PetVaccinationInterface[] = [];

    for (let key in value.vaccination) {
      if (value.vaccination[key]) {
        let data: PetVaccinationInterface = {
          Title: value.vaccination[key].title,
          Date: dateCompilation(value.vaccination[key].date),
          DateNext: dateCompilation(value.vaccination[key].dateNext),
          Type: value.vaccination[key].type
        };
        value.vaccination[key].id
          ? data.Id = value.vaccination[key].id
          : null;
        vaccinations.push(data);
      }
    }*/

    const petData: PetInterface = {
      Name: value.name,
      Type: value.type.value,
      Breed: value.breed ? value.breed.value : null,
      LengthCoat: value.lengthCoat,
      Birthday: value.birthday,
      Gender: value.gender,
      PlaceKeep: value.placeKeep,
      ConditionKeep: value.conditionKeep,
      ChipNumber: value.chipNumber,
      Provenance: value.provenance,
      LastDeworming: value.lastDeworming,
      LastTreatment: value.lastTreatment,
      LastRabies: value.lastRabies,
      LastComprehensive: value.lastComprehensive,
      IsNeutered: value.isNeutered,
      DermatosisHairLoss: value.dermatosisHairLoss,
      HeartFailure: value.heartFailure,
      KidneyDisease: value.kidneyDisease,
      SensitiveDigestion: value.sensitiveDigestion,
      DiseasesJoint: value.diseasesJoint,
      Allergy: value.allergy,
      Obesity: value.obesity,
      LiverDisease: value.liverDisease,
      UrolithiasisDisease: value.urolithiasisDisease,
      Recovery: value.recovery,
      Diabetes: value.diabetes,
      Files: files,
      Size: value.size,
      Growth: growth,
      Weight: weight,
      Mark: value.mark,
      PetUpkeepInformation: value.petUpkeepInformation,
      // Vaccination: vaccinations
    };

    this.registrationPetSubscribe = this.petService.registrationPet(petData)
      .subscribe((response: RegistrationPetResponseInterface) => {
        console.log('RegistrationPetResult', response);
        this.preloaderVisibility = false;
        this.timeout = false;

        if (response.RegistrationPetResult.Success) {
          this.openDialog(
            'Питомец успешно добавлен!',
            'Вы успешно добавили своего питомца.',
            false,
            'back-list'
          );
        } else {
          this.openDialog(
            this.errorTitle,
            this.errorMessage,
            true
          );
        }
      });

    setTimeout(() => {
      if (this.timeout) {
        this.preloaderVisibility = false;
        this.openDialog(
          this.errorTitle,
          this.errorMessage,
          true
        );
        this.registrationPetSubscribe.unsubscribe();
      }
    }, 30000);
  }

  changeFiles() {
    if (this.files.length) {
      this.isFile = true;
      this.files.length > 10 ? this.files.splice(10, this.files.length - 10) : null;
      this.filesFull = this.files.length >= 10;
      this.lastInvalidsChange();
    } else {
      this.showFileError = false;
      this.isFile = false;
    }
  }

  lastInvalidsChange() {
    if (this.lastInvalidFiles) {
      this.showFileError = true;
    } else {
      this.showFileError = false;
    }
  }

  clearFile(index) {
    this.files.splice(index, 1);
    console.log(this.files);
    !this.files.length ? this.isFile = false : null;
    this.showFileError = false;
    this.filesFull = this.files.length >= 10;
  }

  openDialog(
    title: string,
    message: string,
    error: boolean = false,
    back: string = null,
  ) {

    const data: DialogInterface = {
      title: title,
      message: message,
      error: error,
      back: back
    };

    const dialogRef = this.dialog.open(AccountDialog, {
      data: data
    });

    dialogRef.afterClosed().subscribe((result) => {
      result === 'back-list' ? this.router.navigate(['/account', 'pets', 'list']) : null;
    });
  }

}

import { Component, OnInit } from '@angular/core';
import { PetInterface } from '../../../shared/interfaces/pets/pet.interface';
import { api } from '../../../../environments/api';
import { Subscription } from 'rxjs';
import { PetsService } from '../../../shared/services/pets.service';
import { FindPetsResponseInterface } from '../../../shared/interfaces/pets/find-pets-response.interface';
import { UserInterface } from '../../../shared/interfaces/user/user.interface';
import { DialogInterface } from '../../../shared/interfaces/dialog.interface';
import { AccountDialog } from '../../account.component';
import { MatDialog } from '@angular/material';
import { getAvatar } from '../../../shared/functions/functions';

@Component({
  selector: 'app-account-list-pets',
  templateUrl: './account-list-pets.component.html',
  styleUrls: ['./account-list-pets.component.scss']
})
export class AccountListPetsComponent implements OnInit {
  petsEmpty: boolean = true;
  petsList: PetInterface[] = [];
  petsPreList: PetInterface[] = [];

  errorTitle: string = api.mainErrorTitle;
  errorMessage: string = api.mainErrorMessage;
  preloaderVisibility: Boolean = true;
  timeout: boolean = true;

  getPetsSubscribe: Subscription;

  constructor(
    private petsService: PetsService,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    this.getPets();
  }

  getAvatar(images) {
    return getAvatar(images);
  }

  getPets() {
    this.getPetsSubscribe = this.petsService.getPets()
      .subscribe((response: FindPetsResponseInterface) => {
        console.log('FindPetsResult:', response);

        if (response.FindPetsResult.Success) {

          const userData = response.FindPetsResult.UserData as UserInterface;

          this.petsPreList = userData.Pets as PetInterface[];
          this.petsService.setPetsList(userData.Pets);
          this.petListInit();

          this.timeout = false;
          this.preloaderVisibility = false;

        } else {
          this.openDialog(
            this.errorTitle,
            this.errorMessage,
            true
          );
          this.petsEmpty = false;
        }
      });

    setTimeout(() => {
      if (this.timeout) {
        this.openDialog(
          this.errorTitle,
          this.errorMessage,
          true
        );
        this.getPetsSubscribe.unsubscribe();
      }
    }, 30000);
  }

  petListInit() {
    if (this.petsPreList.length) {

      /*for (let key in this.petsPreList) {
        const files = this.petsPreList[key].Files;
        const filesLength = files.length;

        if (filesLength) {
          for(let f = 0; f < files.length; f++) {
            const name = files[f].Name;
            const content = files[f].Content;

            if(name.length) {
              let filename: any = name.split('.');
              filename = filename[filename.length - 1] ;
              this.petsPreList[key].Avatar = 'data:image/' + filename + ';base64,' + content;
              break;
            } else {
              this.petsPreList[key].Avatar = '/assets/images/pets-placeholder.png';
            }
          }
        } else {
          this.petsPreList[key].Avatar = '/assets/images/pets-placeholder.png';
        }
      }*/

      this.petsList = this.petsPreList;
      this.petsEmpty = false;
    }
  }

  openDialog(
    title: string,
    message: string,
    error: boolean = false,
    back: string = null
  ) {

    const data: DialogInterface = {
      title: title,
      message: message,
      error: error,
      back: back
    };

    const dialogRef = this.dialog.open(AccountDialog, {
      data: data
    });
  }

}

import { Component, OnInit } from '@angular/core';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

  constructor(
    private userService: UserService
  ) { }

  ngOnInit() {
  }

  closeMobileSidebar() {
    this.userService.showMobileSidebar = false;
    this.userService.showMobileOverlay = false;
  }

  closeMobileSidebarCheck() {
    this.userService.showMobileSidebar = this.userService.showMobileSidebar ? false : null;
    this.userService.showMobileOverlay = this.userService.showMobileOverlay ? false : null;
  }

}

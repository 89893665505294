import { Pipe, PipeTransform } from '@angular/core';
import { conformToMask } from 'angular2-text-mask';
import { PhoneMask } from '../masks/phone.mask';

@Pipe({
  name: 'phone'
})
export class PhonePipe implements PipeTransform {

  transform(value: any, args?: any): any {

    if(!value) {
      return 'Не указано';
    }

    const phone = conformToMask(value, PhoneMask, {
      guide: false
    }).conformedValue;

    return phone;
  }

}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-account-pets',
  templateUrl: './account-pets.component.html',
  styleUrls: ['./account-pets.component.scss']
})
export class AccountPetsComponent implements OnInit {

  constructor() {}

  ngOnInit() {}
}

import { Component, OnInit } from '@angular/core';
import { api } from '../../../../environments/api';
import { Subscription } from 'rxjs';
import { PetsService } from '../../../shared/services/pets.service';
import { FindPetsResponseInterface } from '../../../shared/interfaces/pets/find-pets-response.interface';
import { PetInterface } from '../../../shared/interfaces/pets/pet.interface';
import { UserInterface } from '../../../shared/interfaces/user/user.interface';
import { ActivatedRoute, Router } from '@angular/router';
import {
  petBreedCat,
  petBreedDog, petConditionKeep,
  petLengthCoat, petPlaceKeep,
  petProvenance,
  petSizes,
  petTypes, petVaccinationType
} from '../../../shared/catalogs/pets.catalog';
import { SelectInterface } from '../../../shared/interfaces/select.interface';
import { FileInterface } from '../../../shared/interfaces/file.interface';
import { DialogInterface } from '../../../shared/interfaces/dialog.interface';
import { AccountDialog } from '../../account.component';
import { MatDialog, MatDialogRef } from '@angular/material';
import { DeletePetResponseInterface } from '../../../shared/interfaces/pets/delete-pet-response.interface';
import { dateEncodeNoUtc } from '../../../shared/functions/functions';

@Component({
  selector: 'app-account-info-pets',
  templateUrl: './account-info-pets.component.html',
  styleUrls: ['./account-info-pets.component.scss']
})
export class AccountInfoPetsComponent implements OnInit {

  petsEmpty: boolean = true;
  petsList: PetInterface[] = [];
  currentPet: PetInterface;

  errorTitle: string = api.mainErrorTitle;
  errorMessage: string = api.mainErrorMessage;
  preloaderVisibility: boolean = true;
  timeout: boolean = true;

  getPetsSubscribe: Subscription;
  deletePetSubscribe: Subscription;

  catalogPetType: SelectInterface[] = petTypes;
  catalogPetBreed: SelectInterface[] = [];
  catalogPetLengthCoat: SelectInterface[] = petLengthCoat;
  catalogPetSize: SelectInterface[] = petSizes;
  catalogPetPlaceKeep: SelectInterface[] = petPlaceKeep;
  catalogPetConditionKeep: SelectInterface[] = petConditionKeep;
  catalogPetProvenance: SelectInterface[] = petProvenance;
  catalogPetVaccination: SelectInterface[] = petVaccinationType;

  currentFiles: FileInterface[] = [];
  carouselImages = [];

  owlOptions = {
    items: 1,
    nav: true,
    dots: true,
    navText: ['<i class="if if-angle-left"></i>', '<i class="if if-angle-right"></i>'],
    autoHeight: true
  };

  showCarousel: boolean = false;

  petIndex: string;

  constructor(
    private petsService: PetsService,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit() {
    this.petIndex = this.route.snapshot.paramMap.get('id');
    this.catalogPetBreed = petBreedCat.concat(petBreedDog);
    this.getPets();
  }

  getPets() {

    if (this.petsService.getPetsList()) {

      this.petsList = this.petsService.getPetsList();
      this.petInfoInit();

    } else {
      this.getPetsSubscribe = this.petsService.getPets()
        .subscribe((response: FindPetsResponseInterface) => {
          console.log('FindPetsResult:', response);

          this.timeout = false;

          if (response.FindPetsResult.Success) {

            const userData = response.FindPetsResult.UserData as UserInterface;

            this.petsList = userData.Pets as PetInterface[];
            this.petsService.setPetsList(userData.Pets);
            this.petInfoInit();

          } else {
            this.openDialog(
              this.errorTitle,
              this.errorMessage,
              true
            );
            this.petsEmpty = false;
          }
        });

      setTimeout(() => {
        if (this.timeout) {
          this.openDialog(
            this.errorTitle,
            this.errorMessage,
            true
          );
          this.getPetsSubscribe.unsubscribe();
        }
      }, 30000);
    }
  }

  petInfoInit() {
    this.currentPet = this.petsList[this.petIndex];

    if (!this.currentPet) {
      this.openDialog(
        'Питомца не существует',
        'Похоже что такого питомца нету в нашей базе, пожалуйста повторите попытку',
        true,
        'back-list'
      );
      return;
    }
    console.log('Current Pet', this.currentPet);

    console.log(1);

    this.showCarousel = true;

    this.currentPet.LastDeworming
      ? this.currentPet.LastDeworming = dateEncodeNoUtc(this.currentPet.LastDeworming)
      : null;

    this.currentPet.LastTreatment
      ? this.currentPet.LastTreatment = dateEncodeNoUtc(this.currentPet.LastTreatment)
      : null;

    this.currentPet.LastRabies
      ? this.currentPet.LastRabies = dateEncodeNoUtc(this.currentPet.LastRabies)
      : null;

    this.currentPet.LastComprehensive
      ? this.currentPet.LastComprehensive = dateEncodeNoUtc(this.currentPet.LastComprehensive)
      : null;

    const petFiles = this.currentPet.Files;
    const petFilesLength = petFiles.length;

    if (petFilesLength) {

      let fileCounter = 0;

      for (let i = 0; i < petFilesLength; i++) {
        const id = petFiles[i].Id;
        const name = petFiles[i].Name;

        if (petFiles[i].Name.length) {

          let filename: any = name.split('.');

          filename = filename[filename.length - 1];

          if(petFiles[i].Content && petFiles[i].Content.length) {
            const content = petFiles[i].Content;
            this.carouselImages.push('data:image/' + filename + ';base64,' + content);

            this.currentFiles.push({
              Id: id,
              Name: name,
              Content: 'data:image/' + filename + ';base64,' + content
            });
          } else if(petFiles[i].Link && petFiles[i].Link.length) {
            this.carouselImages.push(petFiles[i].Link);

            this.currentFiles.push({
              Id: id,
              Name: name,
              Link: petFiles[i].Link
            });
          }

          fileCounter++;

          fileCounter == petFilesLength
            ? this.showCarousel = true
            : null;

        }
      }
    }

    this.preloaderVisibility = false;

  }

  deletePet() {

    this.timeout = true;
    this.preloaderVisibility = true;

    const id = this.currentPet.Id;

    this.deletePetSubscribe = this.petsService.deletePet(id).subscribe((response: DeletePetResponseInterface) => {
      console.log('DeletePetResult:', response);

      this.timeout = false;

      if (response.DeletePetResult.Success) {
        this.openDialog(
          'Питомец удален!',
          'Вы успешно удалили питомца.',
          false,
          'back-list'
        );

      } else {
        this.openDialog(
          this.errorTitle,
          this.errorMessage,
          true
        );
      }
    });

    setTimeout(() => {
      if (this.timeout) {
        this.openDialog(
          this.errorTitle,
          this.errorMessage,
          true
        );
        this.deletePetSubscribe.unsubscribe();
      }
    }, 30000);
  }

  openDialog(
    title: string,
    message: string,
    error: boolean = false,
    back: string = null,
  ) {

    const data: DialogInterface = {
      title: title,
      message: message,
      error: error,
      back: back
    };

    const dialogRef = this.dialog.open(AccountDialog, {
      data: data
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.preloaderVisibility = false;

      result === 'back-list' ? this.router.navigate(['/account', 'pets', 'list']) : null;
    });
  }

  deletePetDialog() {
    const dialogRef = this.dialog.open(DeletePetDialog);

    dialogRef.afterClosed().subscribe((result) => {
      result == 'delete'
        ? this.deletePet()
        : null;
    });
  }

}

@Component({
  selector: 'delete-pet-dialog',
  templateUrl: 'delete-pet-dialog.html',
})
export class DeletePetDialog {
  constructor(
    public dialogRef: MatDialogRef<DeletePetDialog>
  ) {}

  deletePet() {
    this.dialogRef.close('delete');
  }
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DaDataResponse } from '../models/dadata/da-data-response';
import { api } from '../../../environments/api';

export enum DaDataType {
  fio = 'fio',
  address = 'address',
  party = 'party',
  bank = 'bank',
  email = 'email'
}

@Injectable()
export class DadataService {
  apiKey = api.dadataApi;

  constructor(private http: HttpClient) {
  }

  setApiKey(key: string) {
    this.apiKey = key;
  }

  getData(value: string,
          type: DaDataType = DaDataType.address,
          count: number = 10, additional
  ): Observable<DaDataResponse> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Token ' + this.apiKey,
      })
    };

    let body;

    if (additional.fromBound) {
      const from = additional.fromBound;

      const to = additional.toBound ? additional.toBound : additional.fromBound;

      body = {
        query: value,
        count: count,
        from_bound: {
          value: from
        },
        to_bound: {
          value: to
        },
        locations: additional.locations,
        restrict_value: additional.restrict
      };
    } else if(additional.parts) {
      body = {
        query: value,
        count: count,
        parts: additional.parts
      };
    } else {
      body = {
        query: value,
        count: count
      };
    }
    return this.http.post<DaDataResponse>('https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/' + type, body, httpOptions);
  }
}

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from './auth.service';
import { Observable } from 'rxjs';
import { UserService } from './user.service';
import { map } from 'rxjs/operators';
import { ValidateResponseInterface } from '../interfaces/validate-response.interface';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(
    private authService: AuthService,
    private userService: UserService,
    private router: Router
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {

    return this.authService.sessionValidate()
      .pipe(
        map(
          (request: ValidateResponseInterface) => {
            if (request.IsValidResult.Success) {
              return true;
            } else {
              this.router.navigate(['/auth/sign-in'], {
                queryParams: {
                  accessDenied: true
                }
              });
              return false;
            }
          }
        )
      );
  }
}

import { Component, Inject, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../shared/services/auth.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { api } from '../../../environments/api';
import { SimpleLoginResponseInterface } from '../../shared/interfaces/auth/simple-login-response.interface';
import { UserIdAndSecretInterface } from '../../shared/interfaces/user/user-id-and-secret.interface';
import { UpdateUserHashInterface } from '../../shared/interfaces/auth/update-user-hash.interface';
import { RestorePasswordResponseInterface } from '../../shared/interfaces/auth/restore-password-response.interface';
import { UserInterface } from '../../shared/interfaces/user/user.interface';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material';

@Component({
  selector: 'app-restore',
  templateUrl: './restore.component.html',
  styleUrls: ['./restore.component.scss']
})
export class RestoreComponent implements OnInit {

  form: FormGroup;
  errorMessage: string = api.mainErrorMessage;
  errorTitle: string = api.mainErrorTitle;
  preloaderVisibility: Boolean = true;
  timeout: Boolean = true;
  simpleLoginHashSubscribe: Subscription;
  restorePasswordSubscribe: Subscription;
  updateUserHashSubscribe: Subscription;
  hash: string;
  userId: string;
  secretKey: string;
  showPassword: boolean = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private dialog: MatDialog,
  ) {}

  ngOnInit() {
    if (localStorage.getItem('user-key')) {
      this.router.navigate(['/account']);
      return;
    }

    this.hash = this.route.snapshot.queryParamMap.get('hash');

    console.log('Hash', this.hash);

    if (this.hash) {
      this.hash = this.hash.replace(/ /g, '+');
      this.timeout = true;
      this.formInit();
      this.simpleLoginHashSubscribe = this.authService.simpleLoginHash(this.hash)
        .subscribe((response: SimpleLoginResponseInterface) => {

        !api.production ? console.log('SimpleLoginResult', response) : null;

        this.timeout = false;

        const userData = response.SimpleLoginResult.UserData as UserIdAndSecretInterface;

        if (response.SimpleLoginResult.Success) {
          this.userId = userData.UserId;
          this.secretKey = userData.SecretKey;
          this.preloaderVisibility = false;

          const request = {
            userId: this.userId,
            secretKey: this.secretKey
          };

          this.updateUserHashSubscribe = this.authService.updateUserHash(request)
            .subscribe((response: UpdateUserHashInterface) => {
            !api.production ? console.log('UpdateUserHashSubscribe', response) : null;

            if (!response.UpdateSiteUserHashResult.Success) {
              this.openDialog(
                this.errorTitle,
                this.errorMessage,
                true
              );
            }
          });

        } else {

          const warnings = response.SimpleLoginResult.Warnings;

          let errorTitle = this.errorTitle;
          let errorMessage = this.errorMessage;

          for(let key in warnings) {
            if(warnings[key].Code == 502) {
              errorTitle = 'Неверная ссылка!';
              errorMessage = 'Сыылка по которой вы пытаетесь восстановить пароль недействительна. <br> Возможно Вы уже востанавливали по ней пароль. <br> Пожалуйста воспользуйтесь формой восстановления пароля по новой.';
            }
          }

          this.openDialog(
            errorTitle,
            errorMessage,
            true
          );
        }
      });
    } else {
      this.timeout = false;
      setTimeout(() => {
        this.openDialog(
          this.errorTitle,
          this.errorMessage,
          true
        );
      }, 300);
    }

    setTimeout(() => {
      if (this.timeout) {
        this.openDialog(
          this.errorTitle,
          this.errorMessage,
          true
        );
        this.simpleLoginHashSubscribe.unsubscribe();
        this.updateUserHashSubscribe ? this.updateUserHashSubscribe.unsubscribe() : null;
      }
    }, 30000);
  }

  formInit() {
    this.form = new FormGroup({
      'password': new FormControl(
        null,
        [Validators.minLength(5), Validators.required])
    });
  }

  onSubmit() {
    this.timeout = true;
    this.preloaderVisibility = true;

    const request: UserInterface = {
      UserId: this.userId,
      SecretKey: this.secretKey,
      Password: this.form.value.password,
    };

    this.restorePasswordSubscribe = this.authService.restorePassword(request)
      .subscribe((response: RestorePasswordResponseInterface) => {
      !api.production ? console.log('RestorePasswordResult', response) : null;

        this.timeout = false;

      if (response.RestorePasswordResult.Success) {
        this.openDialog(
          'Пароль успешно изменен!',
          'Вы успешно поменяли свой пароль. <br> Теперь попробуйте авторизоваться с помощью нового пароля',
        );
      } else {
        this.openDialog(
          this.errorTitle,
          this.errorMessage,
          true
        );
      }
    });

    setTimeout(() => {
      if (this.timeout) {
        this.openDialog(
          this.errorTitle,
          this.errorMessage,
          true
        );
        this.restorePasswordSubscribe.unsubscribe();
      }
    }, 30000);
  }

  toggleShowPassword() {
    this.showPassword = !this.showPassword;
  }

  openDialog(
    title: string,
    message: string,
    error: boolean = false,
  ) {
    const dialogRef = this.dialog.open(RestoreDialog, {
      data: {
        title: title,
        message: message,
        error: error
      }
    });

    if(error) {
      dialogRef.afterClosed().subscribe(() => {
        this.preloaderVisibility = false;
        this.router.navigate(['/auth/sign-in']);
      });
    } else {
      dialogRef.afterClosed().subscribe(() => {
        this.preloaderVisibility = false;
        this.router.navigate(['/auth/sign-in']);
      });
    }
  }

}

@Component({
  selector: 'restore-dialog',
  templateUrl: 'restore-dialog.html',
})
export class RestoreDialog {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
  ) {}
}

import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { api } from '../../../environments/api';
import { UserService } from './user.service';
import { BaseService } from './base.service';
import { PetInterface } from '../interfaces/pets/pet.interface';

@Injectable()
export class PetsService {

  petsList: PetInterface[];

  constructor(
    private httpService: HttpService,
    private userService: UserService,
    private baseService: BaseService
  ) {}

  getPets() {
    const request = {
      SourceID: api.sourceID,
      SourceSecretKey: api.sourceSecretKey,
      UserData: this.userService.getUserIdAndSecret()
    };

    return this.httpService.postBaseRequest(request, api.paths.pets.findPets);
  }

  setPetsList(data) {
    this.petsList = data;
  }

  getPetsList() {
    return this.petsList;
  }

  registrationPet(data) {

    const userIdAndSecret = this.userService.getUserIdAndSecret();

    const request = {
      SourceID: api.sourceID,
      SourceSecretKey: api.sourceSecretKey,
      UserData: {
        UserId: userIdAndSecret.UserId,
        SecretKey: userIdAndSecret.SecretKey,
        Pet: this.baseService.clearEmpty(data)
      }
    };

    return this.httpService.postBaseRequest(request, api.paths.pets.registrationPet);
  }

  editPet(data) {
    const userIdAndSecret = this.userService.getUserIdAndSecret();

    const request = {
      SourceID: api.sourceID,
      SourceSecretKey: api.sourceSecretKey,
      UserData: {
        UserId: userIdAndSecret.UserId,
        SecretKey: userIdAndSecret.SecretKey,
        Pet: this.baseService.clearEmpty(data)
      }
    };

    return this.httpService.postBaseRequest(request, api.paths.pets.editPet);
  }

  deletePet(data) {
    const userIdAndSecret = this.userService.getUserIdAndSecret();

    const request = {
      SourceID: api.sourceID,
      SourceSecretKey: api.sourceSecretKey,
      UserData: {
        UserId: userIdAndSecret.UserId,
        SecretKey: userIdAndSecret.SecretKey,
        Pet: {
          Id: data
        }
      }
    };

    return this.httpService.postBaseRequest(request, api.paths.pets.deletePet);
  }

}

import { Component, Input, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { UserInterface } from '../../interfaces/user/user.interface';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  @Input() userData: UserInterface;

  constructor(
    private authService: AuthService,
    public userService: UserService
  ) {}

  ngOnInit() {
  }

  onLogout() {
    this.authService.logOut().subscribe(data => {
      console.log(data);
    });
  }

  toggleSidebar() {
    this.userService.showSidebar = !this.userService.showSidebar;
  }

  showMobileNav() {
    this.userService.showMobileNavigation = true;
    this.userService.showMobileOverlay = true;
  }

  closeMobileNav() {
    this.userService.showMobileNavigation = false;
    this.userService.showMobileOverlay = false;
  }

  showMobileSidebar() {
    this.userService.showMobileSidebar = true;
    this.userService.showMobileOverlay = true;
  }

  toggleMobileAccountInfo() {
    this.userService.showMobileAccountInfo = !this.userService.showMobileAccountInfo;
  }

  hideAll() {
    this.userService.showMobileNavigation = false;
    this.userService.showMobileSidebar = false;
    this.userService.showMobileOverlay = false;
  }

}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthRoutingModule } from './auth-routing.module';

import { AuthComponent } from './auth.component';
import { SignInComponent, SignInDialog } from './sign-in/sign-in.component';
import { SignUpComponent, SignUpDialog } from './sign-up/sign-up.component';
import { RecoverComponent, RecoverDialog } from './recover/recover.component';
import { ConfirmComponent, ConfirmDialog } from './confirm/confirm.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { RestoreComponent, RestoreDialog } from './restore/restore.component';
import { MatDialogModule, MatFormFieldModule, MatInputModule } from '@angular/material';
import { OauthComponent, OauthDialog, OauthEmailDialog } from './oauth/oauth.component';

@NgModule({
  imports: [
    CommonModule,
    AuthRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    SharedModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule
  ],
  declarations: [
    SignInComponent,
    SignUpComponent,
    RecoverComponent,
    ConfirmComponent,
    AuthComponent,
    RestoreComponent,
    OauthComponent,
    SignInDialog,
    SignUpDialog,
    RestoreDialog,
    RecoverDialog,
    ConfirmDialog,
    OauthDialog,
    OauthEmailDialog
  ],
  entryComponents: [
    SignInDialog,
    SignUpDialog,
    RestoreDialog,
    RecoverDialog,
    ConfirmDialog,
    OauthDialog,
    OauthEmailDialog
  ]
})
export class AuthModule { }

import { SelectInterface } from '../interfaces/select.interface';

export const gender: SelectInterface[] = [
  {value: 'Male', name: 'Мужской'},
  {value: 'Female', name: 'Женский'}
];

export const weekDays: SelectInterface[] = [
  {value: 'a752dcfb-c499-4462-b96c-e791b6696764',	name: 'Понедельник'},
  {value: 'c3e40532-f401-465e-b907-59dd14532635',	name: 'Вторник'},
  {value: '34367590-94ee-47c6-9c0c-4e7fceae5164',	name: 'Среда'},
  {value: '9f47e8b6-aabd-4582-bae7-582415a4e8c3',	name: 'Четверг'},
  {value: '7ad02a66-8b15-4250-8a84-a71200667317',	name: 'Пятница'},
  {value: 'b4749969-9871-452a-8257-52283e058555',	name: 'Суббота'},
  {value: 'a1053a1e-cad6-4207-b526-d29d83183214',	name: 'Воскресенье'}
];

export const mondayId: string = 'a752dcfb-c499-4462-b96c-e791b6696764';
export const tuesdayId: string = 'c3e40532-f401-465e-b907-59dd14532635';
export const wednesdayId: string = '34367590-94ee-47c6-9c0c-4e7fceae5164';
export const thursdayId: string = '9f47e8b6-aabd-4582-bae7-582415a4e8c3';
export const fridayId: string = '7ad02a66-8b15-4250-8a84-a71200667317';
export const saturdayId: string = 'b4749969-9871-452a-8257-52283e058555';
export const sundayId: string = 'a1053a1e-cad6-4207-b526-d29d83183214';

export const switchRelationTypes: SelectInterface[] = [
  {value: 'cfaa76c3-4d1b-4da1-9b45-d83a66b04d85',	name: 'Владелец'},
  {value: '0da918ae-a888-4764-b47f-f8a203d77fdc',	name: 'Врач'},
  {value: 'a25ca898-792c-44f2-b348-b028fc0ad121',	name: 'Отклонена'},
  {value: '589cd02a-b1b4-4941-934e-91661c3aa834',	name: 'Отменена'},
  {value: 'd08b1ad4-5756-4ec4-8d8f-5e705c1e31b0',	name: 'Скрыта'},
  {value: 'c8ce90e1-d74b-458b-94d6-71b80849956f',	name: 'Ожидает подтверждения'}
];


import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'app-date-changer',
  templateUrl: './date-changer.component.html',
  styleUrls: ['./date-changer.component.scss']
})
export class DateChangerComponent implements OnInit {

  showWeek: boolean = true;
  currentWeek: boolean = true;
  currentMonth: boolean = false;
  startDate = moment().startOf('day');
  dueDate = moment().add(6, 'days').endOf('day');
  startDateFormat;
  dueDateFormat;
  monthDateFormat;

  @Input() init: boolean = false;
  @Output() changeDate = new EventEmitter();

  constructor() {

  }

  ngOnInit() {
    this.changeWeekDates();

    if(this.init) {
      this.emitDates();
    }
  }

  emitDates() {
    this.changeDate.emit([this.startDate, this.dueDate]);
  }

  prevWeek() {
    this.startDate.subtract(7, 'days').startOf('day');
    this.dueDate.subtract(7, 'days').endOf('day');
    this.emitDates();
    this.changeWeekDates();
  }

  nextWeek() {
    this.startDate.add(7, 'days').startOf('day');
    this.dueDate.add(7, 'days').endOf('day');
    this.emitDates();
    this.changeWeekDates();
  }

  goCurrentWeek() {
    if(!this.currentWeek) {
      this.startDate = moment().startOf('day');
      this.dueDate = moment().add(6, 'days').endOf('day');
      this.emitDates();
      this.changeWeekDates();
    }
  }

  prevMonth() {
    this.startDate.subtract(1, 'month').startOf('month');
    this.dueDate.subtract(1, 'month').endOf('month');
    this.changeMonthDates();
  }

  nextMonth() {
    this.startDate.add(1, 'month').startOf('month');
    this.dueDate.add(1, 'month').endOf('month');

    this.changeMonthDates();
  }

  goCurrentMonth() {
    if(!this.currentMonth) {
      this.startDate = moment().startOf('month');
      this.dueDate = moment().endOf('month');
      this.changeMonthDates();
    }
  }

  changeWeekDates() {

    if(this.startDate.format('MM') === this.dueDate.format('MM')) {
      this.startDateFormat = this.startDate.format('DD');
      this.dueDateFormat = this.dueDate.format('DD MMMM YYYY');
    } else if(this.startDate.format('YYYY') != this.dueDate.format('YYYY')) {
      this.startDateFormat = this.startDate.format('DD MMMM YYYY');
      this.dueDateFormat = this.dueDate.format('DD MMMM YYYY');
    } else {
      this.startDateFormat = this.startDate.format('DD MMMM');
      this.dueDateFormat = this.dueDate.format('DD MMMM YYYY');
    }

    this.currentWeek = this.startDate.format('DD.MM.YYYY') === moment().format('DD.MM.YYYY');
  }

  changeMonthDates() {
    this.emitDates();

    this.monthDateFormat = this.startDate.format('MMMM YYYY');

    this.currentMonth = this.startDate.format('MM.YYYY') === moment().format('MM.YYYY');
  }

  changeRangeDates(value) {
    this.showWeek = value;

    if(value) {
      this.currentWeek = false;
      this.goCurrentWeek()
    } else {
      this.currentMonth = false;
      this.goCurrentMonth();
    }
  }

}

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { api } from 'environments/api';

@Injectable()
export class HttpService {

  constructor(
    private httpClient: HttpClient
  ) {}

  /*
  * Post Base request
  * */

  postBaseRequest(data, url) {
    const request = this.formatingRequest(data);

    return this.httpClient.post(api.baseDomain + api.baseUrl + url, request, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
    });
  }

  /*
  * Post Base request text/plain
  * */

  postBaseRequestText(data, url) {
    const request = this.formatingRequest(data);

    return this.httpClient.post(api.baseDomain + api.baseUrlService + url, request, {
      headers: new HttpHeaders()
        .set('Content-Type', 'text/plain')
    });
  }

  /*
  * Post custom
  * */

  postCustom(url, request, headers) {
    return this.httpClient.post(url, request, {
      headers: headers});
  }

  /*
  * Get request
  * */

  getBaseRequest() {}

  /*
  * Post Data Suggestion
  * */

  postBaseCustomUrl(request, url) {
    return this.httpClient.post(api.baseDomain + url, JSON.stringify(request), {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json;charset=utf-8')
    })
  }

  /*
  * Wrap requests in Request Object
  * */

  formatingRequest(obj: any) {
    return JSON.stringify({
      'Request': obj
    });
  }
}

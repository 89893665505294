import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import { AgreementComponent } from './agreement/agreement.component';
import { NotFoundComponent } from './not-found/not-found.component';

const appRoutes: Routes = [
  { path: '', redirectTo: 'auth/sign-in', pathMatch: 'full'},
  { path: 'agreement', component: AgreementComponent},
  { path: '**', component: NotFoundComponent},
];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, {
      anchorScrolling: 'enabled'
    })
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule {}

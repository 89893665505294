import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CustomValidators } from '../../shared/validators/custom.validators';
import { UserService } from '../../shared/services/user.service';
import { api } from '../../../environments/api';
import { Subscription } from 'rxjs/Subscription';
import { EditUserResponseInterface } from '../../shared/interfaces/user/edit-user-response.interface';
import { DialogInterface } from '../../shared/interfaces/dialog.interface';
import { AccountDialog } from '../account.component';
import { MatDialog } from '@angular/material';

@Component({
  selector: 'app-account-change-password',
  templateUrl: './account-change-password.component.html',
  styleUrls: ['./account-change-password.component.scss']
})
export class AccountChangePasswordComponent implements OnInit {

  form: FormGroup;

  errorTitle: string = api.mainErrorTitle;
  errorMessage: string = api.mainErrorMessage;
  preloaderVisibility: boolean = false;
  timeout: boolean = true;

  editUserSubscribe: Subscription;
  getCurrentUserDataSubscribe: Subscription;

  showPassword01: boolean = false;
  showPassword02: boolean = false;
  showPassword03: boolean = false;

  constructor(
    private customValidators: CustomValidators,
    private userService: UserService,
    private dialog: MatDialog
  ) {}

  ngOnInit() {

    this.form = new FormGroup({
      'passwordOld': new FormControl(
        null,
        [Validators.required]),
      'passwordGroup': new FormGroup({
          'password': new FormControl(
            null,
            [Validators.minLength(5), Validators.required]
          ),
          'passwordConfirm': new FormControl(
            null,
            [Validators.required]
          ),
        }, [this.customValidators.passwordMatchValidator]
      )
    });
  }

  onPasswordInput() {
    if (this.form.get('passwordGroup').hasError('mismatch')) {
      this.form.get('passwordGroup.passwordConfirm').setErrors([{'mismatch': true}]);
    } else {
      this.form.get('passwordGroup.passwordConfirm').setErrors(null);
    }
  }

  onSubmit() {
    this.errorMessage = api.mainErrorMessage;

    this.preloaderVisibility = true;

    const value = this.form.value;

    const passwordData = {
      password: value.passwordGroup.password,
      oldPassword: value.passwordOld
    };

    this.editUserSubscribe = this.userService.changeCurrentUserPassword(passwordData)
      .subscribe((response: EditUserResponseInterface) => {

        console.log('EditUserResult', response);
        this.timeout = false;

        if (response.EditUserResult.Success) {
          this.openDialog(
            'Пароль успешно изменен!',
            'Вы успешно поменяли свой пароль.'
          );
        } else {
          const warnings = response.EditUserResult.Warnings;

          let errorMessage = this.errorMessage;
          let errorTitle = this.errorTitle;

          for (let key in warnings) {
            if (warnings[key].Code == 506) {
              errorTitle = 'Ошибка!';
              errorMessage = 'Неверно указан текущий пароль пользователя.';
            }
          }

          this.openDialog(
            errorTitle,
            errorMessage,
            true
          );
        }
      });

    setTimeout(() => {
      if (this.timeout) {
        this.openDialog(
          this.errorTitle,
          this.errorMessage,
          true
        );
        this.editUserSubscribe.unsubscribe();
        this.getCurrentUserDataSubscribe ? this.getCurrentUserDataSubscribe.unsubscribe() : null;
      }
    }, 30000);
  }

  toggleShowPassword01() {
    this.showPassword01 = !this.showPassword01;
  }

  toggleShowPassword02() {
    this.showPassword02 = !this.showPassword02;
  }

  toggleShowPassword03() {
    this.showPassword03 = !this.showPassword03;
  }

  openDialog(
    title: string,
    message: string,
    error: boolean = false,
    back: string = null
  ) {

    const data: DialogInterface = {
      title: title,
      message: message,
      error: error,
      back: back
    };

    const dialogRef = this.dialog.open(AccountDialog, {
      data: data
    });

    dialogRef.afterClosed().subscribe(() => {
      this.preloaderVisibility = false;
    });
  }

}

import { Component, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { PetsService } from '../../../shared/services/pets.service';
import { api } from '../../../../environments/api';
import {
  petBreedCat, petBreedDog,
  petConditionKeep,
  petGender, petIsNeutered,
  petLengthCoat,
  petPlaceKeep,
  petProvenance, petSizes,
  petTypes, petVaccinationType
} from '../../../shared/catalogs/pets.catalog';
import { Subscription } from 'rxjs';
import { DatePipe } from '@angular/common';
import { FindPetsResponseInterface } from '../../../shared/interfaces/pets/find-pets-response.interface';
import { ActivatedRoute, Router } from '@angular/router';
import { EditPetResponseInterface } from '../../../shared/interfaces/pets/edit-pet-response.interface';
import { PetInterface } from '../../../shared/interfaces/pets/pet.interface';
import { FileInterface } from '../../../shared/interfaces/file.interface';
import { SelectInterface } from '../../../shared/interfaces/select.interface';
import { UserInterface } from '../../../shared/interfaces/user/user.interface';
import { PetAdditionalsInterface } from '../../../shared/interfaces/pets/pet-additionals.interface';
import { dateCompilation, dateEncode, downscaleImage } from '../../../shared/functions/functions';
import { DialogInterface } from '../../../shared/interfaces/dialog.interface';
import { AccountDialog } from '../../account.component';
import { MatDialog } from '@angular/material';
import { PetVaccinationInterface } from '../../../shared/interfaces/pets/pet.vaccination.interface';
import { CustomValidators } from '../../../shared/validators/custom.validators';
import { CatalogNamePipe } from '../../../shared/pipes/catalog-name.pipe';

@Component({
  selector: 'app-account-edit-pet',
  templateUrl: './account-edit-pet.component.html',
  styleUrls: ['./account-edit-pet.component.scss']
})
export class AccountEditPetComponent implements OnInit {

  errorTitle: string = api.mainErrorTitle;
  errorMessage: string = api.mainErrorMessage;
  preloaderVisibility: boolean = true;
  timeout: boolean = true;

  petsList: PetInterface[] = [];
  getPetsSubscribe: Subscription;
  editPetSubscribe: Subscription;
  form: FormGroup;
  prevType: string;
  petId: string;
  pet: PetInterface;
  showDogSize: boolean = false;
  petGrowth: string;
  petWeight: string;
  birthday: Date;
  vaccinations: FormArray;

  maxDate: Date = new Date();

  files: File[] = [];
  currentFiles: FileInterface[] = [];
  markFile: FileInterface[] = [];
  newMarkFile: File;
  deletedCurrentFiles: string[] = [];
  isFile: boolean = false;
  lastInvalidFiles: File[] = [];
  showFileError: boolean = false;
  filesFull: boolean = false;
  newFiles: object[] = [];
  petIndex = this.route.snapshot.paramMap.get('id');

  hideBreed = false;

  selectBreed: SelectInterface[] = [];
  selectType: SelectInterface[] = petTypes;
  selectLenthCoat: SelectInterface[] = petLengthCoat;
  selectPlaceKeep: SelectInterface[] = petPlaceKeep;
  selectConditionKeep: SelectInterface[] = petConditionKeep;
  selectProvenance: SelectInterface[] = petProvenance;
  selectGender: SelectInterface[] = petGender;
  selectIsNeutered: SelectInterface[] = petIsNeutered;
  selectSize: SelectInterface[] = petSizes;
  selectVaccination: SelectInterface[] = petVaccinationType;

  constructor(
    private petsService: PetsService,
    private datePipe: DatePipe,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router,
    private customValidators: CustomValidators,
    private catalogNamePipe: CatalogNamePipe
  ) {}

  ngOnInit() {
    this.getPets();
  }

  getPets() {
    this.getPetsSubscribe = this.petsService.getPets()
      .subscribe((response: FindPetsResponseInterface) => {
        console.log('FindPetsResult:', response);

        this.timeout = false;

        if (response.FindPetsResult.Success) {

          const userData = response.FindPetsResult.UserData as UserInterface;

          const pets = userData.Pets as PetInterface[];

          if (pets.length) {
            this.petsList = pets;
            this.formInit();
          } else {
            this.openDialog(
              this.errorTitle,
              this.errorMessage,
              true,
              'back-list'
            );
          }
        } else {
          this.errorMessage = api.mainErrorDataMessage;
          this.openDialog(
            this.errorTitle,
            this.errorMessage,
            true,
            'back-list'
          );
        }
      });

    setTimeout(() => {
      if (this.timeout) {
        this.errorMessage = api.mainErrorDataMessage;
        this.openDialog(
          this.errorTitle,
          this.errorMessage,
          true
        );
        this.getPetsSubscribe.unsubscribe();
      }
    }, 30000);
  }

  formInit() {

    const currentPet: PetInterface = this.petsList[this.route.snapshot.paramMap.get('id')];

    if (!currentPet) {
      this.openDialog(
        'Питомца не существует',
        'Похоже что такого питомца нету в нашей базе, пожалуйста повторите попытку',
        true,
        'back-list'
      );
      return;
    }

    const petFiles = currentPet.Files;
    const petFilesLength = petFiles.length;

    if (petFilesLength) {
      for (let i = 0; i < petFilesLength; i++) {
        const id = petFiles[i].Id;
        const name = petFiles[i].Name;
        const content = petFiles[i].Content;

        if (petFiles[i].Name.length) {

          let filename: any = name.split('.');

          filename = filename[filename.length - 1];

          if(petFiles[i].Content && petFiles[i].Content.length) {
            const content = petFiles[i].Content;

            this.currentFiles.push({
              Id: id,
              Name: name,
              Content: 'data:image/' + filename + ';base64,' + content
            });
          } else if(petFiles[i].Link && petFiles[i].Link.length) {

            this.currentFiles.push({
              Id: id,
              Name: name,
              Link: petFiles[i].Link
            });
          }
        } else {
          this.deletedCurrentFiles.push(id);
        }
      }
    }

    !api.production ? console.log('currentFiles', this.currentFiles) : null;

    currentPet.MarkPhoto && currentPet.MarkPhoto.length ? this.markFile[0] = currentPet.MarkPhoto[0] : null;

    !api.production ? console.log('currentPet', currentPet) : null;

    this.petId = currentPet.Id;

    this.pet = {
      Id: currentPet.Id,
      Name: currentPet.Name ? currentPet.Name : null,
      Type: currentPet.Type ? currentPet.Type : null,
      Breed: currentPet.Breed ? currentPet.Breed : null,
      LengthCoat: currentPet.LengthCoat ? currentPet.LengthCoat : null,
      Birthday: currentPet.Birthday ? currentPet.Birthday : null,
      Gender: currentPet.Gender ? currentPet.Gender : null,
      PlaceKeep: currentPet.PlaceKeep ? currentPet.PlaceKeep : null,
      ConditionKeep: currentPet.ConditionKeep ? currentPet.ConditionKeep : null,
      ChipNumber: currentPet.ChipNumber ? currentPet.ChipNumber : null,
      Provenance: currentPet.Provenance ? currentPet.Provenance : null,
      LastDeworming: currentPet.LastDeworming ? dateEncode(currentPet.LastDeworming) : null,
      LastTreatment: currentPet.LastTreatment ? dateEncode(currentPet.LastTreatment) : null,
      LastRabies: currentPet.LastRabies ? dateEncode(currentPet.LastRabies) : null,
      LastComprehensive: currentPet.LastComprehensive ? dateEncode(currentPet.LastComprehensive) : null,
      IsNeutered: currentPet.IsNeutered ? currentPet.IsNeutered : false,
      DermatosisHairLoss: currentPet.DermatosisHairLoss ? currentPet.DermatosisHairLoss : false,
      HeartFailure: currentPet.HeartFailure ? currentPet.HeartFailure : false,
      KidneyDisease: currentPet.KidneyDisease ? currentPet.KidneyDisease : false,
      SensitiveDigestion: currentPet.SensitiveDigestion ? currentPet.SensitiveDigestion : false,
      DiseasesJoint: currentPet.DiseasesJoint ? currentPet.DiseasesJoint : false,
      Allergy: currentPet.Allergy ? currentPet.Allergy : false,
      Obesity: currentPet.Obesity ? currentPet.Obesity : false,
      LiverDisease: currentPet.LiverDisease ? currentPet.LiverDisease : false,
      UrolithiasisDisease: currentPet.UrolithiasisDisease ? currentPet.UrolithiasisDisease : false,
      Recovery: currentPet.Recovery ? currentPet.Recovery : false,
      Diabetes: currentPet.Diabetes ? currentPet.Diabetes : false,
      Size: currentPet.Size ? currentPet.Size : null,
      Growths: currentPet.Growths,
      Mark: currentPet.Mark,
      Weights: currentPet.Weights,
      PetUpkeepInformation: currentPet.PetUpkeepInformation,
      // Vaccination: currentPet.Vaccination
    };

    this.petGrowth = this.pet.Growths ? this.pet.Growths[this.pet.Growths.length - 1].Value as string : null;
    this.petWeight = this.pet.Weights ? this.pet.Weights[this.pet.Weights.length - 1].Value as string : null;

    if (!this.pet.Type) {
      this.pet.Type = petTypes[0].value as string;
    }

    if (this.pet.Type == petTypes[0].value) {
      this.selectBreed = petBreedCat;
      this.hideBreed = false;
    } else if (this.pet.Type == petTypes[1].value) {
      this.selectBreed = petBreedDog;
      this.showDogSize = true;
      this.hideBreed = false;
    } else {
      this.hideBreed = true;
    }

    this.prevType = this.pet.Type;

    if (this.pet.Birthday) {
      // this.pet.Birthday = this.datePipe.transform(this.pet.Birthday, 'dd.MM.yyyy');
      // this.fpBirthday.defaultDate = this.pet.Birthday;
      this.birthday = new Date(this.pet.Birthday);
    }

    let dewormingDate: Date,
        treatment: Date,
        rabies: Date,
        comprehensive: Date;

    this.pet.LastDeworming
      ? dewormingDate = new Date(this.pet.LastDeworming)
      : null;
    this.pet.LastTreatment
      ? treatment = new Date(this.pet.LastTreatment)
      : null;
    this.pet.LastRabies
      ? rabies = new Date(this.pet.LastRabies)
      : null;
    this.pet.LastComprehensive
      ? comprehensive = new Date(this.pet.LastComprehensive)
      : null;

    this.form = new FormGroup({
      'name': new FormControl(this.pet.Name, Validators.required),
      'type': new FormControl({name: this.catalogNamePipe.transform(this.pet.Type, petTypes), value: this.pet.Type}, [Validators.required, this.customValidators.autofillForceRequire]),
      'breed': new FormControl({name: this.catalogNamePipe.transform(this.pet.Breed, this.selectBreed), value: this.pet.Breed}, [this.customValidators.autofillForceRequire]),
      'lengthCoat': new FormControl(this.pet.LengthCoat),
      'birthday': new FormControl(this.birthday),
      'gender': new FormControl(this.pet.Gender),
      'growth': new FormControl(this.petGrowth),
      'weight': new FormControl(this.petWeight),
      'placeKeep': new FormControl(this.pet.PlaceKeep),
      'conditionKeep': new FormControl(this.pet.ConditionKeep),
      'chipNumber': new FormControl(this.pet.ChipNumber),
      'provenance': new FormControl(this.pet.Provenance),
      'lastDeworming': new FormControl(dewormingDate),
      'lastTreatment': new FormControl(treatment),
      'lastRabies': new FormControl(rabies),
      'lastComprehensive': new FormControl(comprehensive),
      'isNeutered': new FormControl(this.pet.IsNeutered),
      'dermatosisHairLoss': new FormControl(this.pet.DermatosisHairLoss),
      'heartFailure': new FormControl(this.pet.HeartFailure),
      'kidneyDisease': new FormControl(this.pet.KidneyDisease),
      'sensitiveDigestion': new FormControl(this.pet.SensitiveDigestion),
      'diseasesJoint': new FormControl(this.pet.DiseasesJoint),
      'allergy': new FormControl(this.pet.Allergy),
      'obesity': new FormControl(this.pet.Obesity),
      'liverDisease': new FormControl(this.pet.LiverDisease),
      'urolithiasisDisease': new FormControl(this.pet.UrolithiasisDisease),
      'recovery': new FormControl(this.pet.Recovery),
      'diabetes': new FormControl(this.pet.Diabetes),
      'size': new FormControl(this.pet.Size),
      'mark': new FormControl(this.pet.Mark),
      'petUpkeepInformation': new FormControl(this.pet.PetUpkeepInformation),
      // 'vaccination': new FormArray([])
    });

    /*this.vaccinations = this.form.get('vaccination') as FormArray;

    const vaccinations = this.pet.Vaccination;

    if (vaccinations && vaccinations.length) {
      for (let key in vaccinations) {
        this.vaccinations.push(
          new FormGroup({
            'id': new FormControl(vaccinations[key].Id),
            'title': new FormControl(vaccinations[key].Title, [Validators.required]),
            'date': new FormControl(new Date(dateEncode(vaccinations[key].Date)), [Validators.required]),
            'dateNext': new FormControl(new Date(dateEncode(vaccinations[key].DateNext)), [Validators.required]),
            'type': new FormControl(vaccinations[key].Type, [Validators.required])
          })
        );
      }
    }*/

    this.preloaderVisibility = false;
  }

  addVaccination(): void {
    this.vaccinations.push(
      new FormGroup({
        'id': new FormControl(),
        'title': new FormControl(null, [Validators.required]),
        'date': new FormControl(null, [Validators.required]),
        'dateNext': new FormControl(null, [Validators.required]),
        'type': new FormControl(this.selectVaccination[0].value, [Validators.required])
      })
    );
  }

  removeVaccination(i): void {
    this.vaccinations.removeAt(i);
  }

  changeType() {
    !api.production ? console.log('fast-test', this.form.value.type) : null;

    if (this.form.value.type.value != this.prevType) {

      this.prevType = this.form.value.type.value;

      if (this.form.value.type.value == petTypes[0]['value']) {
        this.selectBreed = petBreedCat;
        this.showDogSize = false;
        this.hideBreed = false;
        this.form.controls['size'].setValue(null);
      } else if (this.form.value.type.value == petTypes[1]['value']) {
        this.selectBreed = petBreedDog;
        this.showDogSize = true;
        this.hideBreed = false;
      } else {
        this.hideBreed = true;
      }

      this.form.value.breed = null;
    }
  }

  preSubmit() {
    if (this.files.length) {

      let convertFiles = [];

      for (let i = 0; i < this.files.length; i++) {
        let reader = new FileReader();

        const name = this.files[i].name;
        const file = this.files[i];

        reader.readAsDataURL(file);

        reader.onload = () => {
          const content = downscaleImage(reader.result, 600).split(',')[1];

          convertFiles.push({
            Name: name,
            Content: content
          });
        };
      }

      const interval = setInterval(() => {
        if (convertFiles.length == this.files.length) {
          clearInterval(interval);
          console.log('array', convertFiles);
          console.log(convertFiles[0]);

          this.newFiles = convertFiles;
          this.markPhotoLoad();
        }
      }, 100);
    } else {
      this.markPhotoLoad();
    }
  }

  markPhotoLoad() {
    if(this.newMarkFile) {
      let reader = new FileReader();
      reader.readAsDataURL(this.newMarkFile);

      if(!this.markFile.length) {
        this.markFile.push({
          Name: 'mark-photo.jpg',
          Content: ''
        })
      }

      reader.onload = () => {
        this.markFile[0].Content = downscaleImage(reader.result, 600).split(',')[1];
        this.markFile[0].Name = this.newMarkFile.name;
        this.onSubmit();
      };
    } else {
      this.onSubmit();
    }
  }

  onSubmit() {
    this.timeout = true;

    const value = this.form.value;

    console.log(value);

    let files = [];

    files = this.newFiles;

    for (let i = 0; i < this.deletedCurrentFiles.length; i++) {
      if (files[i]) {
        files[i].Id = this.deletedCurrentFiles[i];
      } else {
        files[i] = {
          Id: this.deletedCurrentFiles[i],
          Name: '',
          Content: ''
        };
      }
    }

    console.log('files', files);

    let markFile = this.markFile;

    this.preloaderVisibility = true;

    if (value.birthday && value.birthday != this.pet.Birthday) {
      value.birthday = this.datePipe.transform(value.birthday, 'MM.dd.yyyy');
    }

    value.lastDeworming
      ? value.lastDeworming = dateCompilation(value.lastDeworming)
      : null;

    value.lastTreatment
      ? value.lastTreatment = dateCompilation(value.lastTreatment)
      : null;

    value.lastRabies
      ? value.lastRabies = dateCompilation(value.lastRabies)
      : null;

    value.lastComprehensive
      ? value.lastComprehensive = dateCompilation(value.lastComprehensive)
      : null;

    const growth: PetAdditionalsInterface = value.growth
      ? {
        Date: this.datePipe.transform(new Date(), 'MM.dd.yyyy'),
        Value: value.growth
      }
      : null;

    const weight: PetAdditionalsInterface = value.weight
      ? {
        Date: this.datePipe.transform(new Date(), 'MM.dd.yyyy'),
        Value: value.weight
      }
      : null;

    let vaccinations: PetVaccinationInterface[] = [];

    for (let key in value.vaccination) {
      if (value.vaccination[key]) {
        let data: PetVaccinationInterface = {
          Title: value.vaccination[key].title,
          Date: dateCompilation(value.vaccination[key].date),
          DateNext: dateCompilation(value.vaccination[key].dateNext),
          Type: value.vaccination[key].type
        };
        value.vaccination[key].id
          ? data.Id = value.vaccination[key].id
          : null;
        vaccinations.push(data);
      }
    }

    const petType = value.type.value ? value.type.value : value.type;
    const petBreed = value.breed.value ? value.breed.value : value.breed;

    const petData: PetInterface = {
      Id: this.petId,
      Name: value.name,
      Type: petType,
      Breed: petBreed,
      LengthCoat: value.lengthCoat,
      Birthday: value.birthday,
      Gender: value.gender,
      PlaceKeep: value.placeKeep,
      ConditionKeep: value.conditionKeep,
      ChipNumber: value.chipNumber,
      Provenance: value.provenance,
      LastDeworming: value.lastDeworming,
      LastTreatment: value.lastTreatment,
      LastRabies: value.lastRabies,
      LastComprehensive: value.lastComprehensive,
      IsNeutered: value.isNeutered,
      DermatosisHairLoss: value.dermatosisHairLoss,
      HeartFailure: value.heartFailure,
      KidneyDisease: value.kidneyDisease,
      SensitiveDigestion: value.sensitiveDigestion,
      DiseasesJoint: value.diseasesJoint,
      Allergy: value.allergy,
      Obesity: value.obesity,
      LiverDisease: value.liverDisease,
      UrolithiasisDisease: value.urolithiasisDisease,
      Recovery: value.recovery,
      Diabetes: value.diabetes,
      Files: files,
      Size: value.size,
      Growth: growth,
      Weight: weight,
      MarkPhoto: markFile,
      Mark: value.mark,
      PetUpkeepInformation: value.petUpkeepInformation,
      Vaccination: vaccinations
    };

    console.log('Submit pet data', petData);

    this.editPetSubscribe = this.petsService.editPet(petData).subscribe((response: EditPetResponseInterface) => {
      console.log('EditPetResult', response);
      this.preloaderVisibility = false;
      this.timeout = false;

      if (response.EditPetResult.Success) {
        this.openDialog(
          'Информация о питомце успешно изменена',
          'Вы успешно изменили инормацию о питомце.',
          false,
          'back-pet-profile'
        );
      } else {
        this.openDialog(
          this.errorTitle,
          this.errorMessage,
          true
        );
      }
    });

    setTimeout(() => {
      if (this.timeout) {
        this.preloaderVisibility = false;
        this.openDialog(
          this.errorTitle,
          this.errorMessage,
          true
        );
        this.editPetSubscribe.unsubscribe();
      }
    }, 30000);
  }

  changeFiles() {
    if (this.files.length) {
      this.isFile = true;

      this.files.length + this.currentFiles.length > 10
        ?
        this.files.splice(this.files.length,
          this.files.length + this.currentFiles.length - 10)
        :
        null;

      this.filesFull = this.currentFiles.length >= 10
        ||
        this.files.length >= 10
        ||
        this.currentFiles.length + this.files.length >= 10;

      this.lastInvalidsChange();
    } else {
      this.showFileError = false;
      this.isFile = false;
    }
  }

  lastInvalidsChange() {
    this.showFileError = !!this.lastInvalidFiles;
  }

  clearFile(index) {
    this.files.splice(index, 1);
    console.log(this.files);
    !this.currentFiles.length && !this.files.length ? this.isFile = false : null;
    this.showFileError = false;

    if (this.currentFiles.length >= 10 || this.files.length >= 10 || this.currentFiles.length + this.files.length >= 10) {
      this.filesFull = true;
    } else {
      this.filesFull = false;
    }
  }

  clearMarkFile() {
    this.markFile[0].Content = '';
  }

  clearMarkNewFile() {
    this.newMarkFile = undefined;
  }

  clearCurrentFile(index) {
    this.deletedCurrentFiles.push(this.currentFiles[index].Id);
    this.currentFiles.splice(index, 1);
    console.log(this.currentFiles);
    !this.currentFiles.length && !this.files.length ? this.isFile = false : null;
    this.showFileError = false;

    if (this.currentFiles.length >= 10 || this.files.length >= 10 || this.currentFiles.length + this.files.length >= 10) {
      this.filesFull = true;
    } else {
      this.filesFull = false;
    }
  }

  openDialog(
    title: string,
    message: string,
    error: boolean = false,
    back: string = null,
  ) {

    const data: DialogInterface = {
      title: title,
      message: message,
      error: error,
      back: back
    };

    const dialogRef = this.dialog.open(AccountDialog, {
      data: data
    });

    dialogRef.afterClosed().subscribe((result) => {
      result === 'back-list'
        ? this.router.navigate(['/account', 'pets', 'list'])
        : null;
      result === 'back-pet-profile'
        ? this.router.navigate(['/account', 'pets', 'info', this.route.snapshot.paramMap.get('id')])
        : null;
    });
  }

}

import { Injectable } from '@angular/core';

import { api } from '../../../environments/api';

import { HttpService } from './http.service';
import { BaseService } from './base.service';

import { UserIdAndSecretInterface } from '../interfaces/user/user-id-and-secret.interface';
import { UserRequestInterface } from '../interfaces/user/user-request.interface';
import { UserPasswordInterface } from '../interfaces/user/user-password.interface';
import { UserFullResponseInterface } from '../interfaces/user/user-full-response.interface';
import { UserInterface } from '../interfaces/user/user.interface';
import { LoginInterface } from '../interfaces/auth/login.interface';
import { CookieService } from 'ngx-cookie-service';
import { SwitchRelationTypeInterface } from '../interfaces/user/switch-relation-type/switch-relation-type.interface';

@Injectable()
export class UserService {

  userData: UserFullResponseInterface;
  saveSession: boolean;
  userAvatar: string;

  showSidebar: boolean = true;
  showMobileNavigation: boolean = false;
  showMobileSidebar: boolean = false;
  showMobileAccountInfo: boolean = false;
  showMobileOverlay: boolean = false;

  constructor(
    private baseService: BaseService,
    private httpService: HttpService,
    private cookie: CookieService
  ) {}

  /*
  * Set Save Session Current User
  * */

  setSaveSession(val) {
    this.saveSession = val;
  }

  /*
  * Set Current User Data
  * */

  setUserData(data) {
    this.userData = data as UserFullResponseInterface;
  }

  /*
  * Get Current User Data
  * */

  getUserData() {
    return this.userData;
  }

  /*
  * Find user
  * */

  findUserEmail(email) {
    const request: LoginInterface = {
      SourceID: api.sourceID,
      SourceSecretKey: api.sourceSecretKey,
      AuthorizationType: 'Email',
      UserData: {
        Email: email
      }
    };

    return this.httpService.postBaseRequest(request, api.paths.user.findUser);
  }

  /*
  * Find user from any types
  * */

  findUserTypes(userData, type) {
    const request: LoginInterface = {
      SourceID: api.sourceID,
      SourceSecretKey: api.sourceSecretKey,
      AuthorizationType: type,
      UserData: userData
    };

    return this.httpService.postBaseRequest(request, api.paths.user.findUser);
  }

  /*
  *
  * Confirmations account
  *
  * */

  /*
  * Request confirmation again
  * */

  sendConfirmationCode(type) {
    const request: UserRequestInterface = {
      SourceID: api.sourceID,
      SourceSecretKey: api.sourceSecretKey,
      AuthorizationType: type,
      UserData: this.getUserIdAndSecret()
    };

    return this.httpService.postBaseRequest(request, api.paths.user.sendConfirmationCode);
  }

  /*
  * Check confirmation needed
  * */

  needConfirmation(type) {
    const request: UserRequestInterface = {
      SourceID: api.sourceID,
      SourceSecretKey: api.sourceSecretKey,
      AuthorizationType: type,
      UserData: this.getUserIdAndSecret()
    };

    return this.httpService.postBaseRequest(request, api.paths.user.needCommunicationConfirmation);
  }

  /*
  *
  * Working with Users Data
  *
  * */

  getUserIdAndSecret(): UserIdAndSecretInterface {
    return {
      UserId: localStorage.getItem('user-id'),
      SecretKey: localStorage.getItem('user-key')
    }
  }

  /*
  * Get Data about current User from server
  * */

  getCurrentUserData() {
    const request: UserRequestInterface = {
      SourceID: api.sourceID,
      SourceSecretKey: api.sourceSecretKey,
      UserData: this.getUserIdAndSecret()
    };

    return this.httpService.postBaseRequest(request, api.paths.user.currentUser);
  }

  /*
  * Edit current User Data
  * */

  editCurrentUserData(editData: UserInterface) {
    editData.UserId = this.getUserIdAndSecret().UserId;
    editData.SecretKey = this.getUserIdAndSecret().SecretKey;

    const request: UserRequestInterface = {
      SourceID: api.sourceID,
      SourceSecretKey: api.sourceSecretKey,
      UserData: this.baseService.clearEmpty(editData)
    };

    return this.httpService.postBaseRequest(request, api.paths.user.editUser);

  }

  /*
  * Change password current User
  * */

  changeCurrentUserPassword(passwordsData) {
    const userIdAndSecret = this.getUserIdAndSecret();

    const request: UserRequestInterface = {
      SourceID: api.sourceID,
      SourceSecretKey: api.sourceSecretKey,
      UserData: <UserPasswordInterface> {
        Password: passwordsData.password,
        OldPassword: passwordsData.oldPassword,
        UserId: userIdAndSecret.UserId,
        SecretKey: userIdAndSecret.SecretKey
      }
    };

    return this.httpService.postBaseRequest(request, api.paths.user.editUser);
  }

  /*
  * Change photo current User
  * */

  changePhoto(file) {
    const request = {
      SourceID: api.sourceID,
      SourceSecretKey: api.sourceSecretKey,
      UserData: this.getUserIdAndSecret(),
      Files: [
        file
      ]
    };

    return this.httpService.postBaseRequest(request, api.paths.user.editUser);
  }

  /*
  * Смена статуса пользователя по отношении к клинике
  * */

  switchRelationType(data) {

    const userData = this.getUserIdAndSecret();

    const request: SwitchRelationTypeInterface = {
      SourceID: api.sourceID,
      SourceSecretKey: api.sourceSecretKey,
      UserData: {
        UserId: userData.UserId,
        SecretKey: userData.SecretKey,
        AccountId: data.AccountId
      },
      Invite: {
        UserId: data.UserId
      },
      LinkTypeId: data.LinkTypeId
    };

    return this.httpService.postBaseRequest(request, api.paths.user.switchRelationType);
  }

  /*
  * Clear local storage data
  * */

  clearLocalData() {
    this.setUserData(null);
    this.userAvatar = null;
    this.showSidebar = true;
    this.showMobileNavigation = false;
    this.showMobileSidebar = false;
    this.showMobileAccountInfo = false;
    this.showMobileOverlay = false;
    localStorage.removeItem('user-id');
    localStorage.removeItem('user-key');
    localStorage.removeItem('auth-expires');
  }

  /*
  *
  * Others
  *
  * */


}

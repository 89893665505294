import {
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  Renderer2,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { Subject ,  timer } from 'rxjs';
import { debounce, } from 'rxjs/operators';
import { DaDataResponse } from '../../models/dadata/da-data-response';
import { DaDataSuggestion } from '../../models/dadata/suggestion';
import { DaDataConfig, DaDataConfigDefault } from '../../da-data-config';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DaDataAddress, DaDataBank, DaDataEmail, DaDataFIO, DaDataParty } from '../../models/dadata/data';
import { DaDataType, DadataService } from '../../services/dadata.service';

const NGX_DADATA_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => DadataComponent),
  multi: true
};

export function createDaDataValidator(value) {
  return (c: FormControl) => {
    const err = {
      rangeError: {
        given: c.value,
        expected: value,
      }
    };

    return (c.value !== value) ? err : null;
  };
}

@Component({
  selector: 'dadata',
  templateUrl: './dadata.component.html',
  styleUrls: ['./dadata.component.scss'],
  providers: [NGX_DADATA_VALUE_ACCESSOR]
})
export class DadataComponent implements OnInit, ControlValueAccessor, OnChanges {
  private _value: any = '';
  currentFocus = -1;
  additional;

  data: DaDataSuggestion[] = [];

  @Input() config: DaDataConfig = DaDataConfigDefault;
  @Input() apiKey: string;
  @Input() disabled = null;
  @Input() type = DaDataType.address;
  @Input() limit = DaDataConfigDefault.limit;
  @Input() placeholder = '';
  @Input() fromBound = '';
  @Input() toBound = '';
  @Input() restrict: Boolean = false;
  @Input() locations: any[] = [];
  @Input() parts = [''];

  @Output() selectedSuggestion: DaDataSuggestion;
  @Output() selected = new EventEmitter<DaDataSuggestion>();

  @Output('suggestion') suggestionEvent = new EventEmitter();

  @ViewChild('inputValue') inputValue: ElementRef;

  private inputString$ = new Subject<string>();

  onTouched = () => {
  };
  propagateChange: any = () => {
  };
  validateFn: any = () => {
  };

  constructor(private dataService: DadataService, private _r: Renderer2) {
  }

  get value(): any {
    return this._value;
  }

  set value(v: any) {
    if (v !== this._value) {
      this._value = v;
      this.propagateChange(v);
    }
  }

  ngOnInit() {

    this.dataService.setApiKey(this.apiKey ? this.apiKey : this.config.apiKey);

    this.inputString$.pipe(
      debounce(() => timer(this.config.delay ? this.config.delay : 500)),
    ).subscribe(x => {
      this.additional = {
        parts: this.parts,
        fromBound: this.fromBound,
        toBound: this.toBound,
        locations: this.locations,
        restrict: this.restrict
      };

      this.dataService.getData(x, this.type, this.limit, this.additional).subscribe((y: DaDataResponse) => {
        this.data = y.suggestions;
      });
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.value) {
      this.suggestionEvent.emit(changes.value)
    }
  }

  onInputBlur() {
    !this.disabled ? this.onTouched() : null
  }

  getData(value: string) {
    this.inputString$.next(value);
    this.currentFocus = -1;
  }

  onClick(e: MouseEvent, item: DaDataSuggestion) {
    this.inputValue.nativeElement.value = item.value;
    this.propagateChange(item.value);
    this.inputValue.nativeElement.focus();
    this.selectedSuggestion = item;
    this.data = [];
    this.currentFocus = -1;

    this.selected.emit(item);

  }

  @HostListener('document:click')
  onOutsideClick() {
    this.data = [];
  }

  onArrowDown() {
    this.removeFocus(this.currentFocus);
    if (this.currentFocus >= this.data.length - 1) {
      this.currentFocus = 0;
    } else {
      this.currentFocus++;
    }
    this.setFocus(this.currentFocus);
  }

  onArrowUp() {
    this.removeFocus(this.currentFocus);
    if (this.currentFocus === 0) {
      this.currentFocus = this.data.length - 1;
    } else {
      this.currentFocus--;
    }
    this.setFocus(this.currentFocus);
  }

  onEnter() {
    this.selectedSuggestion = this.data[this.currentFocus];
    this.inputValue.nativeElement.value = this.selectedSuggestion.value;
    this.data = [];
    this.currentFocus = -1;
    this.propagateChange(this.selectedSuggestion.value);
    // this.writeValue(this.selectedSuggestion.value);
    this.selected.emit(this.selectedSuggestion);
    // this.selectedData.emit(this.selectedSuggestion.data);
    // this.selectedString.emit(this.selectedSuggestion.value);
  }

  setFocus(id: number) {
    const activeEl = document.getElementById(id + 'item');
    this._r.addClass(activeEl, 'active');
  }

  removeFocus(id: number) {
    if (id !== -1) {
      const activeEl = document.getElementById(id + 'item');
      this._r.removeClass(activeEl, 'active');
    }
  }

  writeValue(value: any): void {
    if (value !== undefined) {
      this._value = value;
    }
    // this.onSuggestionSelected(value);
  }

  /**
   * Set the function to be called
   * when the control receives a change event.
   *
   * @param fn a function
   */
  registerOnChange(fn: any): void {
    // this.onSuggestionSelected = fn;
    this.propagateChange = fn;
  }

  /**
   * Set the function to be called
   * when the control receives a touch event.
   *
   * @param fn a function
   */
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  /**
   * Implements disabled state for this element
   *
   * @param isDisabled
   */
  setDisabledState(isDisabled: boolean): void {
    alert('disabled!');
    this.disabled = isDisabled;
  }
}

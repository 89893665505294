import {NgModule} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
import { HttpService } from './services/http.service';
import { BaseService } from './services/base.service';
import { UserService } from './services/user.service';
import { AuthService } from './services/auth.service';
import { AuthGuard } from './services/auth.guard';
import { PetsService } from './services/pets.service';
import { DadataService } from './services/dadata.service';
import { DadataComponent } from './components/dadata/dadata.component';
import { CommonModule } from '@angular/common';
import { CustomValidators } from './validators/custom.validators';
import { HeaderComponent } from './components/header/header.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { AccountSidebarComponent } from './components/account-sidebar/account-sidebar.component';
import { RouterModule } from '@angular/router';
import { AccountChangePhotoComponent } from './components/account-change-photo/account-change-photo.component';
import { ngfModule } from 'angular-file';
import { ImageCropperModule } from './plugins/cropper';
import { MatAutocompleteModule, MatDialogModule, MatFormFieldModule, MatInputModule, MatSnackBarModule } from '@angular/material';
import { DadataMatComponent } from './components/dadata-mat/dadata-mat.component';
import { NavToggleDirective } from './directives/nav-toggle.directive';
import { DisableControlDirective } from './directives/disable-control.directive';
import { PreloaderComponent } from './components/preloader/preloader.component';
import { StatusDialogComponent } from './components/status-dialog/status-dialog.component';
import { SubmitDialogComponent } from './components/submit-dialog/submit-dialog.component';
import { DialogService } from './services/dialog.service';
import { DateChangerComponent } from './components/date-changer/date-changer.component';
import { ForceAutocompleteComponent } from './components/force-autocomplete/force-autocomplete.component';

@NgModule({
  imports: [
    ReactiveFormsModule,
    CommonModule,
    FormsModule,
    HttpClientModule,
    RouterModule,
    ngfModule,
    ImageCropperModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatAutocompleteModule,
    MatSnackBarModule
  ],
  exports: [
    DadataComponent,
    HeaderComponent,
    SidebarComponent,
    AccountSidebarComponent,
    DadataMatComponent,
    DisableControlDirective,
    PreloaderComponent,
    DateChangerComponent,
    ForceAutocompleteComponent
  ],
  declarations: [
    DadataComponent,
    DadataMatComponent,
    HeaderComponent,
    SidebarComponent,
    AccountSidebarComponent,
    AccountChangePhotoComponent,
    NavToggleDirective,
    DisableControlDirective,
    PreloaderComponent,
    StatusDialogComponent,
    SubmitDialogComponent,
    DateChangerComponent,
    ForceAutocompleteComponent
  ],
  entryComponents: [
    AccountChangePhotoComponent,
    StatusDialogComponent,
    SubmitDialogComponent
  ],
  providers: [
    BaseService,
    HttpService,
    AuthService,
    AuthGuard,
    UserService,
    DadataService,
    CustomValidators,
    DialogService,
    PetsService
  ]
})
export class SharedModule {
}

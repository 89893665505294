import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../shared/services/user.service';
import { AuthService } from '../shared/services/auth.service';
import { CurrentUserResponseInterface } from '../shared/interfaces/user/current-user-response.interface';
import { Subscription } from 'rxjs';
import { api } from '../../environments/api';
import { FileInterface } from '../shared/interfaces/file.interface';
import { UserInterface } from '../shared/interfaces/user/user.interface';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';
import { DialogInterface } from '../shared/interfaces/dialog.interface';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})
export class AccountComponent implements OnInit {

  avatarFiles: FileInterface[];
  avatar: string;
  avatarId: string;
  showAvatarCap: boolean = true;
  preloaderVisibility: boolean = true;
  timeout: boolean = true;
  getCurrentUserData: Subscription;
  errorTitle: string = api.mainErrorTitle;
  errorMessage: string = api.mainErrorDataMessage;
  userData: UserInterface;

  constructor(
    private router: Router,
    public userService: UserService,
    private authService: AuthService,
    private dialog: MatDialog
  ) {
  }

  ngOnInit() {

    if (!this.userService.getUserData()) {
      this.getCurrentUserData = this.userService.getCurrentUserData()
        .subscribe((response: CurrentUserResponseInterface) => {

          console.log('CurrentUser', response);

          this.timeout = false;

          if (response.CurrentUserResult.Success) {
            this.preloaderVisibility = false;
            this.userService.setUserData(response.CurrentUserResult);
            this.userData = response.CurrentUserResult.UserData;
            this.getUserAvatar();

            this.userData.TypeId != api.typeUser ? this.authService.logOut() : null;

          } else {
            this.openDialog(
              this.errorTitle,
              this.errorMessage,
              true
            );
          }
        });

      setTimeout(() => {
        if (this.timeout) {
          this.openDialog(
            this.errorTitle,
            this.errorMessage,
            true
          );
          this.getCurrentUserData.unsubscribe();
        }
      }, 30000);
    } else {
      this.preloaderVisibility = false;
      this.getUserAvatar();
      this.userData = this.userService.getUserData().UserData;
    }
  }

  getUserAvatar() {

    this.avatarFiles = this.userService.getUserData().Files;

    if (this.avatarFiles.length) {

      const last = this.avatarFiles.length - 1;

      const name = this.avatarFiles[last].Name;
      const content = this.avatarFiles[last].Content;
      this.avatarId = this.avatarFiles[last].Id;

      let filename: any = name.split('.');

      filename = filename[filename.length - 1];

      this.avatar = 'data:image/' + filename + ';base64,' + content;

      this.userService.userAvatar = this.avatar;

      this.showAvatarCap = false;
    } else {
      this.showAvatarCap = true;
    }
  }

  openDialog(
    title: string,
    message: string,
    error: boolean = false,
    back: string = null
  ) {

    const data: DialogInterface = {
      title: title,
      message: message,
      error: error,
      back: back
    };

    const dialogRef = this.dialog.open(AccountDialog, {
      data: data
    });
  }
}

@Component({
  selector: 'account-dialog',
  templateUrl: 'account-dialog.html',
})
export class AccountDialog {
  constructor(
    public dialogRef: MatDialogRef<AccountDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogInterface
  ) {
  }

  onNoClick() {
    this.dialogRef.close(this.data.back);
  }
}

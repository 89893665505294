import { Component, OnInit } from '@angular/core';
import { AuthService } from './shared/services/auth.service';
import { UserService } from './shared/services/user.service';
import { api } from '../environments/api';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  constructor(
    private authService: AuthService,
    private userService: UserService
  ) {}

  ngOnInit() {
    const expires = localStorage.getItem('auth-expires');

    !api.production ? console.log('fast-test', expires, new Date().valueOf()) : null;

    if (expires && parseInt(expires) < new Date().valueOf()) {
      this.userService.clearLocalData();
    }
  }
}

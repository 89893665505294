import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';
import { UserService } from '../../shared/services/user.service';
import { FindUserResponseInterface } from '../../shared/interfaces/user/find-user/find-user-response.interface';
import { LoginResponseInterface } from '../../shared/interfaces/auth/login-response.interface';
import { UserIdAndSecretInterface } from '../../shared/interfaces/user/user-id-and-secret.interface';
import { CurrentUserResponseInterface } from '../../shared/interfaces/user/current-user-response.interface';
import { UserInterface } from '../../shared/interfaces/user/user.interface';
import { api } from '../../../environments/api';
import { CookieService } from 'ngx-cookie-service';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';
import { AuthService } from '../../shared/services/auth.service';
import { RegisterResponseInterface } from '../../shared/interfaces/auth/register-response.interface';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CustomValidators } from '../../shared/validators/custom.validators';
import { RegisterUserInterface } from '../../shared/interfaces/auth/register-user.interface';

@Component({
  selector: 'app-oauth',
  templateUrl: './oauth.component.html',
  styleUrls: ['./oauth.component.scss']
})
export class OauthComponent implements OnInit {

  errorTitle: string = api.mainErrorTitle;
  errorMessage: string = api.mainErrorMessage;
  timeout: boolean = true;

  params;
  type: string;
  email: string;

  checkUserSubscribe: Subscription;
  loginSubscribe: Subscription;
  getDataSubscribe: Subscription;
  signUpSubscribe: Subscription;

  constructor(
    private route: ActivatedRoute,
    private userService: UserService,
    private authService: AuthService,
    private cookie: CookieService,
    private dialog: MatDialog,
    private router: Router
  ) {}

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      console.log(params);
      this.params = params;
      this.checkSocial();
    });
  }

  checkSocial() {
    switch (this.params.provider) {
      case 'vkontakte':
        this.type = 'VkId';
        break;
      case 'facebook':
        this.type = 'FacebookId';
        break;
      case 'instagram':
        this.type = 'InstagramId';
        break;
      case 'google_oauth2':
        this.type = 'GoogleId';
        break;
      default:
        this.openDialog(
          this.errorTitle,
          this.errorMessage,
          true
        );
        return;
    }

    this.checkUser();
  }

  checkUser() {

    let userData = {};

    userData[this.type] = this.params.uid;

    const timeout = setTimeout(() => {
      this.openDialog(
        this.errorTitle,
        this.errorMessage,
        true
      );
      this.checkUserSubscribe ? this.checkUserSubscribe.unsubscribe() : null;
    }, 30000);

    this.checkUserSubscribe = this.userService.findUserTypes(userData, this.type)
      .subscribe((response:FindUserResponseInterface) => {
        if(response.FindUserResult.Success) {
          this.userLogin(userData);
          clearTimeout(timeout);
        } else {
          this.userGetEmail();
          clearTimeout(timeout);
        }
      }, () => {
        clearTimeout(timeout);
        this.openDialog(
          'Ошибка сервера!',
          'Сервер временно не доступен. Повторите попытку позже.',
          true
        );
      });

  }

  userLogin(userData) {
    const timeout = setTimeout(() => {
      this.openDialog(
        this.errorTitle,
        this.errorMessage,
        true
      );
      this.loginSubscribe ? this.loginSubscribe.unsubscribe() : null;
    }, 30000);

    this.loginSubscribe = this.authService.socialLogin(userData, this.type)
      .subscribe((response: LoginResponseInterface) => {

        !api.production ? console.log('Login Result:', response) : null;

        if (response.LoginResult.Success) {

          const userData: UserIdAndSecretInterface = response.LoginResult.UserData as UserIdAndSecretInterface;

          /*
          * Set cookie time in conformity with type session
          * */

          let date = new Date();
          const expires = new Date(new Date().setDate(date.getDate() + 7));

          localStorage.setItem('user-key', userData.SecretKey);
          localStorage.setItem('user-id', userData.UserId);
          localStorage.setItem('auth-expires', expires.toString());

          /*
          * Check user type
          * */

          this.getDataSubscribe = this.userService.getCurrentUserData()
            .subscribe((response: CurrentUserResponseInterface) => {

              console.log('CurrentUserResult', response);

              clearTimeout(timeout);

              if (response.CurrentUserResult.Success) {

                const userData: UserInterface = response.CurrentUserResult.UserData as UserInterface;

                if (userData.TypeId === api.typeUser) {
                  this.userService.setUserData(response.CurrentUserResult);
                  this.router.navigate(['/account']);
                } else {
                  this.openDialog(
                    'Неверный тип пользователя!',
                    'Вы пытаетесь авторизоваться аккаунтом не в том разделе нашего сервиса. <br> Убедитесь что вы на странице специалистов или клиник (в соответствии с вашим аккаунтом).',
                    true
                  );
                }
              } else {
                this.openDialog(
                  this.errorTitle,
                  this.errorMessage,
                  true
                );
              }
            });
        } else {
          clearTimeout(timeout);
          this.openDialog(
            this.errorTitle,
            this.errorMessage,
            true
          );
        }
      });
  }

  userGetEmail() {
    if(this.params['info[email]']) {
      this.email = this.params['info[email]'];
      this.userRegister();
      // this.openDialogEmail();
    } else {
      this.openDialogEmail();
    }
  }

  userRegister() {

    const password: string = this.generatePassword(10);

    const names = this.params['info[name]'].split(' ');

    let userData:RegisterUserInterface = {
      Email: this.email,
      Password: password,
      City: this.params['extra[raw_info][city][title]'],
      Firstname: names[0],
      Surname: names[1],
      TypeId: api.typeUser
    };

    userData[this.type] = this.params.uid;

    this.signUpSubscribe = this.authService.signUp(userData)
      .subscribe((response: RegisterResponseInterface) => {

        !api.production ? console.log('RegistrationResult', response) : null;

        if (response.RegistrationResult.Success) {

          const userData: UserIdAndSecretInterface =
            response.RegistrationResult.UserData as UserIdAndSecretInterface;

          let date = new Date();
          const expires = new Date(new Date().setDate(date.getDate() + 7));
          this.cookie.set('user-key', userData.SecretKey, expires, '/');
          this.cookie.set('user-id', userData.UserId, expires, '/');

          this.getDataSubscribe = this.userService.getCurrentUserData()
            .subscribe((response: CurrentUserResponseInterface) => {
              this.timeout = false;

              if (response.CurrentUserResult.Success) {
                this.userService.setUserData(response.CurrentUserResult);

                !api.production ? console.log('Current User Data:', response) : null;

                this.openDialog(
                  'Регистрация прошла успешно!',
                  'Вам на почту отправленна ссылка для подтверждения регистрации. <br> Пожалуйста, перейдите по ней, чтобы пользоваться всеми возможностями нашего сервиса. <br> Спасибо!'
                );
              }
            });
        } else {
          let error = api.mainErrorMessage;

          let userError: boolean = false;

          const warnings = response.RegistrationResult.Warnings;

          for(let key in warnings) {
            if(warnings[key].Code == 503) {
              userError = true;
              error = 'Пользователь с таким E-mail уже существует.';
            }
          }

          this.timeout = false;

          if(userError) {
            this.openDialog(
              'Пользователь существует!',
              error,
              true,
              true
            );
          } else {
            this.openDialog(
              this.errorTitle,
              error,
              true
            );
          }

        }
      });

    setTimeout(() => {
      if (this.timeout) {
        this.openDialog(
          this.errorTitle,
          this.errorMessage,
          true
        );
        this.signUpSubscribe.unsubscribe();
        this.getDataSubscribe ? this.getDataSubscribe.unsubscribe() : null;
      }
    }, 30000);
  }

  generatePassword(length) {
    const uppercase = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const lowercase = 'abcdefghijklmnopqrstuvwxyz';
    const numbers = '0123456789';
    const symbols = '!"#$%&\'()*+,-./:;<=>?@^[\\]^_`{|}~';
    const all = uppercase + lowercase + numbers + symbols;
    let password = '';
    for (let index = 0; index < length; index++) {
      let character = Math.floor(Math.random() * all.length);
      password += all.substring(character, character + 1);
    }

    return password;
  }

  openDialog(
    title: string,
    message: string,
    error: boolean = false,
    restore: boolean = false,
  ) {
    const dialogRef = this.dialog.open(OauthDialog, {
      data: {
        title: title,
        message: message,
        error: error,
        restore: restore
      }
    });

    if(error) {
      dialogRef.afterClosed().subscribe((result) => {
        this.authService.logOut(false);
        result != 'no-redirect' ? this.router.navigate(['/']) : null;
      });
    } else {
      dialogRef.afterClosed().subscribe(() => {
        this.router.navigate(['/account']);
      });
    }
  }

  openDialogEmail() {
    const dialogRef = this.dialog.open(OauthEmailDialog);

    dialogRef.afterClosed().subscribe(result => {
      if(result === 'back') {
        this.router.navigate(['/']);
      } else {
        this.email = result;
        this.userRegister();
      }
    });
  }

}

@Component({
  selector: 'oauth-dialog',
  templateUrl: 'oauth-dialog.html',
})
export class OauthDialog {
  constructor(
    public dialogRef: MatDialogRef<OauthDialog>,
    @Inject(MAT_DIALOG_DATA) public data,
    private router: Router
  ) {}

  goRecover() {
    this.dialogRef.close('no-redirect');
    this.router.navigate(['/auth/recover']);
  }
}

@Component({
  selector: 'oauth-email-dialog',
  templateUrl: 'oauth-email-dialog.html'
})
export class OauthEmailDialog {

  email: string;
  form: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<OauthEmailDialog>,
    private router: Router,
    private customValidators: CustomValidators
  ) {
    this.form = new FormGroup({
      'email': new FormControl(null, [Validators.required, this.customValidators.email])
    });
  }

  onSubmit() {
    this.dialogRef.close(this.form.value.email);
  }

  onNoClick() {
    this.dialogRef.close('back');
  }
}

import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { AccountRoutingModule } from './account-routing.module';
import { AccountComponent, AccountDialog } from './account.component';
import { AccountEditComponent } from './account-edit/account-edit.component';
import { AccountInfoComponent } from './account-info/account-info.component';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { TextMaskModule } from 'angular2-text-mask';
import { SharedModule } from '../shared/shared.module';
import { AccountChangePasswordComponent } from './account-change-password/account-change-password.component';
import {
  DateAdapter, MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
  MatAutocompleteModule, MatCheckboxModule,
  MatDatepickerModule,
  MatDialogModule,
  MatFormFieldModule,
  MatInputModule, MatNativeDateModule,
  MatSelectModule
} from '@angular/material';
import { AccountPetsComponent } from './account-pets/account-pets.component';
import { AccountAddPetComponent } from './account-pets/account-add-pet/account-add-pet.component';
import { AccountEditPetComponent } from './account-pets/account-edit-pet/account-edit-pet.component';
import { AccountInfoPetsComponent, DeletePetDialog } from './account-pets/account-info-pets/account-info-pets.component';
import { AccountListPetsComponent } from './account-pets/account-list-pets/account-list-pets.component';
import { ngfModule } from 'angular-file';
import { PipesModule } from '../shared/pipes/pipes.module';
import { OwlModule } from 'ngx-owl-carousel';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { CatalogNamePipe } from '../shared/pipes/catalog-name.pipe';

export const MY_FORMATS = {
  parse: {
    dateInput: 'L',
  },
  display: {
    dateInput: 'L',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'L',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@NgModule({
  imports: [
    CommonModule,
    AccountRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    TextMaskModule,
    SharedModule,
    ngfModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatAutocompleteModule,
    MatSelectModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatCheckboxModule,
    PipesModule,
    OwlModule
  ],
  declarations: [
    AccountComponent,
    AccountEditComponent,
    AccountInfoComponent,
    AccountChangePasswordComponent,
    AccountDialog,
    AccountPetsComponent,
    AccountAddPetComponent,
    AccountEditPetComponent,
    AccountInfoPetsComponent,
    AccountListPetsComponent,
    DeletePetDialog
  ],
  entryComponents: [
    AccountDialog,
    DeletePetDialog
  ],
  providers: [
    DatePipe,
    CatalogNamePipe,
    MatDatepickerModule,
    {provide: MAT_DATE_LOCALE, useValue: 'ru-RU'},
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS}
  ]
})
export class AccountModule { }
